import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Text, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import { Configuration, DefaultApi } from "@feedloop/qore-sdk";
import Editor from "@monaco-editor/react";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import useSWR from "swr";
import env from "../../../utils/env";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";

const fromTypes = ["table", "view"];

registerNode(
  definition,
  (props) => {
    const from = props.formRef.watch("options.from");

    const { data: globalVar } = useSWR<{
      QORE_DATA_URL: string;
      QORE_DATA_ADMIN_SECRET: string;
    }>("context", async (params) => {
      const resp = await axios.get(
        `${env.NEXT_PUBLIC_API_ENDPOINT}/api/${params}`,
        {
          headers: {
            "x-qonduit-admin-secret": Cookies.get("qonduit-access-token"),
          },
        }
      );
      return resp.data.global;
    });

    const client = React.useMemo(
      () =>
        new DefaultApi(
          new Configuration({
            basePath: globalVar?.QORE_DATA_URL,
            apiKey: globalVar?.QORE_DATA_ADMIN_SECRET,
          })
        ),
      [globalVar]
    );

    const schema = useSWR(
      ["schema", globalVar?.QORE_DATA_URL, client],
      async () => {
        const resp = await client.getSchema();
        return typeof resp.data === "object" ? resp.data : undefined;
      }
    );

    const tableNames = React.useMemo(() => {
      return schema?.data?.tables.map((table) => table.name) || [];
    }, [schema?.data?.tables]);
    const viewNames = React.useMemo(() => {
      return (
        schema?.data?.tables
          .map(
            (table) =>
              table.views?.map((view) => view.name as unknown as string) || []
          )
          .flat() || []
      );
    }, [schema?.data?.tables]);

    const handleParamsChange = React.useCallback(
      (value?: string) => {
        if (!value) return;
        props.formRef.setValue("options.params", JSON.parse(value));
      },
      [props.formRef]
    );

    return (
      <VStack spacing="5" alignItems="normal">
        {/* <FormControl isRequired={true}>
          <FormLabel fontSize="14px">URL</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<BiLinkExternal />}
            />
            <Input
              size="sm"
              placeholder="qore data url"
              backgroundColor="white"
              {...props.formRef.register("options.url")}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="14px">Admin Secret</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<BiKey />} />
            <Input
              size="sm"
              placeholder="qore data admin secret"
              backgroundColor="white"
              type="password"
              {...props.formRef.register("options.adminSecret")}
            />
          </InputGroup>
        </FormControl> */}
        <FormControl>
          <FormLabel>From</FormLabel>
          <Select {...props.formRef.register("options.from")}>
            {fromTypes.map((fromType) => (
              <option value={fromType} key={fromType}>
                {fromType}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Select
            key={schema?.data?.tables ? "0" : "1"}
            {...props.formRef.register("options.name")}
          >
            {(from === "table" ? tableNames : viewNames).map((name) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Offset</FormLabel>
          <Input type="number" {...props.formRef.register("options.offset")} />
        </FormControl>

        <FormControl>
          <FormLabel>Limit</FormLabel>
          <Input type="number" {...props.formRef.register("options.limit")} />
        </FormControl>

        <FormControl>
          <FormLabel>Params</FormLabel>
          <Editor
            height="150px"
            defaultLanguage="json"
            defaultValue={JSON.stringify(props.instance.options.params)}
            options={{
              minimap: { enabled: false },
              lineNumbers: "off",
            }}
            onChange={handleParamsChange}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            URL
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.url}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Table/view
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.name}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Parameters
          </Text>
          <Editor
            height="150px"
            defaultLanguage="javascript"
            value={JSON.stringify(props.instance.options.params)}
            options={{
              minimap: { enabled: false },
              readOnly: true,
              lineNumbers: "off",
            }}
          />
        </Flex>
      </VStack>
    );
  }
);
