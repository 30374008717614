import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ButtonGroup,
  Button,
  VStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { UseDisclosureProps } from "@chakra-ui/hooks";
import { useForm } from "react-hook-form";
import { QonduitWorkflow } from "../../server/workflowBuilder";

function RenameWorkflowModal({
  modalDisclosure,
  workflow,
  onSubmit: handleEditWorkflow,
}: {
  modalDisclosure: UseDisclosureProps;
  workflow: QonduitWorkflow;
  onSubmit: ({ title }: { title: string }) => Promise<void>;
}) {
  const { handleSubmit, register, watch } = useForm();
  const title = watch("title");
  return (
    <>
      <Modal
        isOpen={modalDisclosure.isOpen!}
        onClose={modalDisclosure.onClose!}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent padding="24px 16px 24px 16px">
          <ModalHeader>
            <Text fontSize="24px">Rename this pipeline</Text>
            <Text fontSize="14px" color="#6F809A" fontWeight="400">
              Type a new name for this pipeline
            </Text>
          </ModalHeader>
          <form onSubmit={handleSubmit(handleEditWorkflow)}>
            <ModalBody>
              <VStack spacing={6}>
                <FormControl isRequired>
                  <FormLabel>Current name</FormLabel>
                  <Input
                    size="md"
                    backgroundColor="#F9F9FA"
                    color="#A6B0C1"
                    border="1px solid #D4D8DE"
                    fontSize="14px"
                    defaultValue={workflow?.title}
                    disabled
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="new">New name</FormLabel>
                  <Input
                    id="new"
                    size="md"
                    fontSize="14px"
                    placeholder="New name"
                    {...register("title", {
                      shouldUnregister: true,
                    })}
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center" paddingTop="24px">
              <ButtonGroup width="100%" size="lg" spacing={3}>
                <Button
                  width="50%"
                  onClick={modalDisclosure.onClose}
                  fontSize="16px"
                >
                  Cancel
                </Button>
                <Button
                  width="50%"
                  colorScheme="blue"
                  type="submit"
                  variant="solid"
                  fontSize="16px"
                  disabled={!title}
                >
                  Rename
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RenameWorkflowModal;
