import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure, useTimeout } from "@chakra-ui/hooks";
import { Text, VStack } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { Portal } from "@chakra-ui/portal";
import { Icon } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { Tooltip } from "@chakra-ui/tooltip";
import React, { useState } from "react";
import { Edge, Elements, useStoreState } from "react-flow-renderer";
import { BiDownload, BiGitMerge, BiPlug, BiPlus } from "react-icons/bi";
import { InstanceData, SubscriptionData } from "../../utils/QonduitConverter";
import { menuItemStyle, menuListStyle } from "../../utils/theme";
import AddProcessorDrawer from ".././AddProcessorDrawer";
import QonduitContext from ".././QonduitContext";

export default function AddProcessorMenu({
  position,
  output,
  instanceID,
}: {
  position: string;
  output: string;
  instanceID?: string;
}) {
  const addProcessorDisclosure = useDisclosure();
  const buttonMenuDisclosure = useDisclosure();
  const qonduitActions = QonduitContext.useActions();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [type, setType] = useState<{ button: string }>({ button: "" });
  const edges: Edge<SubscriptionData>[] = useStoreState((state) => state.edges);
  const selectedElements: Elements<InstanceData> | null = useStoreState(
    (state) => state.selectedElements
  );

  const isSubscribe = React.useMemo(() => {
    if (!selectedElements || selectedElements[0]?.id === "startNode")
      return true;
    else {
      const subscriptions = edges.filter(
        (edge) =>
          edge.source.split("_")[1] ===
            selectedElements[0]?.data?.instance?.id &&
          edge.sourceHandle === output
      );
      return subscriptions.length ? false : true;
    }
  }, [edges, selectedElements]);

  const toast = useToast();

  const sourceElementConnect = QonduitContext.useSelectState(
    (state) => state.sourceElement
  );

  const handleConnectProcessor = React.useCallback(() => {
    qonduitActions.connect({
      id: selectedElements![0]?.id,
      output,
      position,
      subscriptions: selectedElements![0]?.data?.instance.subscriptions!,
    });
    !sourceElementConnect.id.length &&
      toast({
        description: "Select another block to create connection",
        status: "info",
        duration: 100000,
        isClosable: true,
        onCloseComplete: () =>
          qonduitActions.connect({
            id: "",
            output: "",
            position: "",
            subscriptions: [],
          }),
      });
  }, [selectedElements, output, sourceElementConnect]);

  const isConnect = React.useMemo(() => {
    return (
      instanceID === sourceElementConnect.id.split("_")[1] &&
      output === sourceElementConnect.output &&
      position === sourceElementConnect.position
    );
  }, [sourceElementConnect, instanceID]);

  const isConnectIcon = React.useMemo(() => {
    return isConnect ? <BiPlug color="white" /> : <BiPlus />;
  }, [isConnect]);

  const isConnectBg = React.useMemo(() => {
    return isConnect ? "#0065FF" : "white";
  }, [isConnect]);

  const cancelConnect = React.useCallback(() => {
    if (isConnect) {
      toast.closeAll();
      setTimeout(() => {
        buttonMenuDisclosure.onClose();
      }, 1);
    }
  }, [isConnect]);

  let isDisableMenu = React.useMemo(() => {
    return isConnect ? false : buttonMenuDisclosure.isOpen;
  }, [isConnect, buttonMenuDisclosure]);

  const tooltipLabel = React.useMemo(() => {
    return isConnect ? "Cancel Connect" : "Create or connect...";
  }, [isConnect]);

  return (
    <>
      <Menu
        autoSelect={false}
        placement={"bottom"}
        isOpen={isDisableMenu}
        onOpen={buttonMenuDisclosure.onOpen}
        onClose={buttonMenuDisclosure.onClose}
        isLazy
      >
        <Tooltip
          hasArrow
          label={tooltipLabel}
          bg="#001E4D"
          fontSize="12px"
          isOpen={isTooltipOpen}
        >
          <MenuButton
            className="drag-handle"
            as={IconButton}
            aria-label="Add Processor"
            bg={isConnectBg}
            onClick={cancelConnect}
            size="sm"
            variant="outline"
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            fontSize="23"
            icon={isConnectIcon}
            direction="ltr"
          />
        </Tooltip>
        <Portal>
          <MenuList style={menuListStyle} zIndex="10">
            {/* TODO: Uncomment when add serial processor has been provided on
            backend
            <MenuItem
              icon={
                <Icon
                  as={BiDownload}
                  fontSize={"17px"}
                  color={"#A2A2A2 !important"}
                />
              }
              onClick={() => {
                addProcessorDisclosure.onOpen();
                setType({ button: "serial" });
                buttonMenuDisclosure.onClose();
              }}
              _hover={menuItemStyle}
            >
              <Text fontSize="sm" fontWeight="normal">
                Insert block here
              </Text>
            </MenuItem>
            {position === "bottom" && (
              <MenuItem
                icon={
                  <Icon
                    as={BiGitMerge}
                    fontSize={"17px"}
                    color={"#A2A2A2 !important"}
                  />
                }
                disabled={isSubscribe}
                onClick={() => {
                  addProcessorDisclosure.onOpen();
                  setType({ button: "parallel" });
                  buttonMenuDisclosure.onClose();
                }}
                _hover={menuItemStyle}
              >
                <Text fontSize="sm" fontWeight="normal">
                  Add parallel block
                </Text>
              </MenuItem>
            )} */}
            {/* TODO: Delete MenuItem when add serial processor has been provided */}
            <MenuItem
              icon={
                <Icon
                  as={BiGitMerge}
                  fontSize={"17px"}
                  color={"#A2A2A2 !important"}
                />
              }
              disabled={isSubscribe}
              onClick={() => {
                addProcessorDisclosure.onOpen();
                setType({ button: "parallel" });
                buttonMenuDisclosure.onClose();
              }}
              _hover={menuItemStyle}
            >
              <Text fontSize="sm" fontWeight="normal">
                Add processor
              </Text>
            </MenuItem>
            <MenuItem
              icon={
                <Icon
                  as={BiPlug}
                  fontSize={"17px"}
                  color={"#A2A2A2 !important"}
                />
              }
              _hover={menuItemStyle}
              onClick={() => {
                handleConnectProcessor();
                buttonMenuDisclosure.onClose();
              }}
            >
              <Text fontSize="sm" fontWeight="normal">
                Connect this block to...
              </Text>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <AddProcessorDrawer
        position={position}
        addProcessorDisclosure={addProcessorDisclosure}
        output={output}
        type={type}
      />
    </>
  );
}
