import { Button, ButtonGroup } from "@chakra-ui/button";
import { UseDisclosureProps } from "@chakra-ui/hooks";
import { Text, VStack } from "@chakra-ui/layout";
import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { ModalFooter, ModalHeader } from "@chakra-ui/react";
import React from "react";

interface Content {
  [key: string]: {
    title: string;
    description: string;
    submitButton: string;
    buttonColor: string;
  };
}

const data: Content = {
  delete: {
    title: "Are you sure you want to delete",
    description: "Caution: this action is not undoable!",
    submitButton: "Delete",
    buttonColor: "red",
  },
  revert: {
    title: "Revert all unsaved",
    description:
      "If you choose “Revert”, your workflow will be resetted to last saved and all unsaves changes will be discarded",
    submitButton: "Revert",
    buttonColor: "red",
  },
  save: {
    title: "Save your pipeline before run",
    description:
      "Any unsaved changes will be saved if you choose “Save”. Otherwise, choose “Cancel”",
    submitButton: "Save and Run",
    buttonColor: "blue",
  },
  saveNextWorkflow: {
    title: "Save changes before leaving",
    description:
      "There are some unsaved changes in your workflow. If you want to discard changes, choose “Cancel”",
    submitButton: "Save",
    buttonColor: "blue",
  },
  stop: {
    title: "Stop running Workflow",
    description:
      "Workflow system will be stopped. If you want to edit your workflow, choose “Stop”",
    submitButton: "Stop",
    buttonColor: "blue",
  },
};

export default function WorkflowDecisionModal({
  modalDisclosure,
  modalType,
  workflowTitle,
  submit,
  cancelAction,
}: {
  modalDisclosure: UseDisclosureProps;
  modalType: string;
  workflowTitle?: string;
  submit: () => void;
  cancelAction: () => void;
}) {
  const title = React.useMemo(() => {
    return `${data[modalType].title} ${
      workflowTitle ? `”${workflowTitle}”` : ""
    }?`;
  }, [workflowTitle, modalType]);
  return (
    <>
      <Modal
        isOpen={modalDisclosure.isOpen!}
        onClose={modalDisclosure.onClose!}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent padding="24px 16px 24px 16px">
          <ModalHeader textAlign="center">
            <Text fontSize="24px" lineHeight="32px" marginBottom="12px">
              {title}
            </Text>
            <Text
              color="#6F809A"
              fontWeight="400"
              fontSize="13px"
              lineHeight="16px"
            >
              {data[modalType].description}
            </Text>
          </ModalHeader>
          <ModalFooter justifyContent="center" paddingTop="24px">
            <ButtonGroup width="100%" size="lg" spacing={3}>
              <Button width="50%" onClick={cancelAction} fontSize="16px">
                Cancel
              </Button>
              <Button
                width="50%"
                colorScheme={data[modalType].buttonColor}
                variant="solid"
                fontSize="16px"
                onClick={submit}
              >
                {data[modalType].submitButton}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
