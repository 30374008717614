import { JSONSchemaType } from "ajv";
import { JSONSchema7Object } from "json-schema";
import { v4 } from "uuid";
import { sourceT } from "wonka/dist/types/src/Wonka_types.gen";
import * as Bi from "react-icons/bi";
import { ProcessorInstance } from "./ProcessorInstance";
import { extend } from "dayjs";

export interface QonduitProcessorDefinition<
  TResourceType extends QonduitResourceTypes = QonduitResourceTypes,
  TResourceCategory extends QonduitResourceCategories = QonduitResourceCategories,
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object,
  TOutputs extends string = string
> {
  id: string;
  type: TResourceType;
  category: TResourceCategory;
  options: TSchemaObject;
  color: string;
  copywrite: string;
  icon: keyof typeof Bi;
  outputs: TOutputs[];
  schema: JSONSchemaType<TSchemaObject>;
}

export type StreamReference = {
  workflowID: string;
  instanceID: string;
  bucket: string;
};

export function deserializeStream(serialized: string): StreamReference {
  const [workflowID, instanceID, bucket] = serialized.split("_");
  return { workflowID, instanceID, bucket };
}

export function groupId(id: string) {
  return `qonduit_${id}`;
}

export function buildProcessorInstance<
  TResourceType extends QonduitResourceTypes = QonduitResourceTypes,
  TResourceCategory extends QonduitResourceCategories = QonduitResourceCategories,
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object,
  TOutputs extends string = string
>(
  definition: QonduitProcessorDefinition<
    TResourceType,
    TResourceCategory,
    TSchemaObject,
    TOutputs
  >,
  options: Partial<TSchemaObject> = {}
): ProcessorInstance<TSchemaObject> {
  return {
    id: v4(),
    options: { ...definition.options, ...options },
    processorID: definition.id,
    subscriptions: [],
    metadata: {
      createdAt: new Date().toISOString(),
      description: "",
      name: `${definition.id}`,
      x: 0,
      y: 0,
    },
  };
}

export enum QonduitResourceTypes {
  Source = "source",
  Operator = "operator",
  Sink = "sink",
}

export type Committed = {
  id: string;
  to: string;
  at: Date;
  traceID: string;
};

export type QonduitResourceCategories =
  | "trigger"
  | "logic and flow"
  | "operation"
  | "api"
  | "integration"
  | "data table";

export type QonduitFileMetadata = Record<
  string,
  {
    timestamp: string;
  } & JSONSchema7Object
>;

export abstract class QonduitTransport<
  TSchemaObject extends QonduitFile = QonduitFile
> {
  abstract subscribe(
    channels: string[],
    groupName: string
  ): sourceT<TSchemaObject>;
  abstract pull(): Promise<QonduitFile[]> 
  abstract publish(channel: string, data: TSchemaObject): Promise<Committed>;
  async initialize() {}
  async stats(
    channels: string[],
    fullIdentifier?: boolean
  ): Promise<{ total: number; outputs: Record<string, number> }> {
    return { total: 0, outputs: {} };
  }

  async consumerGroupStat(
    groupId: string,
    channels: string[]
  ): Promise<number> {
    return 0;
  }
}

export interface QonduitFile<
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object
> {
  id: string;
  channel: string;
  data: TSchemaObject;
  metadata: QonduitFileMetadata;
}

export interface FileMetadata extends JSONSchema7Object {
  id: string;
  location: string;
  cursor: number;
  fields: string[];
  mimetype: string | false;
  timestamp: string;
  size: number;
}
