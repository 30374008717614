import useSWR from "swr";
import { useClient } from "../utils/QonduitClient";
import { useWorkflowBuilder } from "./WorkflowBuilderProvider";

export default function useProcessorStats(id: string) {
  const builder = useWorkflowBuilder();
  const client = useClient();
  return useSWR(
    ["processor stats", id],
    async () => {
      const resp = await client.getWorkflowInstanceStats(id, builder.state.id);
      return resp.data as unknown as {
        inputs: Record<string, number>;
        outputs: Record<string, number>;
        total: number;
        processed: number;
      };
    },
    { refreshInterval: 5000 }
  );
}
