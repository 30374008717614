import { FormControl, VStack } from "@chakra-ui/react";
import React from "react";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";
import Editor from "@monaco-editor/react";

registerNode(
  definition,
  (props) => {
    const handleChange = React.useCallback(
      (val?: string) => {
        if (!val) return;
        props.formRef.setValue("options.function", val);
      },
      [props.formRef]
    );
    return (
      <VStack spacing="5" alignItems="normal">
        <FormControl>
          <Editor
            height="300px"
            width="100%"
            defaultLanguage="javascript"
            defaultValue={props.instance.options.function}
            onChange={handleChange}
            options={{ minimap: { enabled: false } }}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Editor
          height="150px"
          defaultLanguage="javascript"
          value={props.instance.options.function}
          options={{
            minimap: { enabled: false },
            readOnly: true,
            lineNumbers: "off",
          }}
        />
      </VStack>
    );
  }
);
