import {
  FormControl,
  FormLabel,
  Select,
  VStack,
  Input,
  Textarea,
  Button,
  ButtonGroup,
  Text,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";
import React from "react";
import QonduitContext from "../../../components/QonduitContext";

registerNode(
  definition,
  (props) => {
    return (
      <VStack spacing="4" alignItems="end">
        <FormControl>
          <FormLabel>Account ID</FormLabel>
          <Input
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.accountID")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Token</FormLabel>
          <Input
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.token")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Service</FormLabel>
          <Select
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.service")}
          >
            <option value="whatsapp">Whatsapp</option>
            <option value="sms">SMS</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Sender</FormLabel>
          <Input
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.sender")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.phone")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Message</FormLabel>
          <Textarea
            size="sm"
            backgroundColor="white"
            {...props.formRef.register("options.message")}
          ></Textarea>
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Sender number
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.sender}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Receiver number
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.phone}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Service
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.service}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
