import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Sink,
  "integration",
  {
    accountID: string;
    service: "sms" | "whatsapp";
    token: string;
    phone: string;
    sender: string;
    message: string;
  },
  "Success" | "Failed"
>({
  id: "Twilio Message Service",
  type: QonduitResourceTypes.Sink,
  category: "integration",
  color: "#4D93FF",
  copywrite: "Send a message via Twilio",
  icon: "BiMessage",
  options: {
    accountID: "your account id",
    message: "Hello",
    phone: "phone",
    service: "whatsapp",
    sender: "your sender phone",
    token: "your twilio token",
  },
  schema: {
    type: "object",
    required: ["message", "phone", "sender", "token", "accountID"],
    properties: {
      service: { type: "string", enum: ["whatsapp", "sms"] },
      message: { type: "string" },
      phone: { type: "string" },
      sender: { type: "string" },
      token: { type: "string" },
      accountID: { type: "string" },
    },
  },
  outputs: ["Success", "Failed"],
});
