import { buildProcessorDefinition } from "../../definitions";
import { FileMetadata } from "../../interfaces";

export type FileUploadMetadata = FileMetadata & {
  status: "error" | "uploaded" | "processing" | "success" | "paused";
};
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Source,
  "trigger",
  {
    files: Record<string, FileUploadMetadata>;
    importFields: string[] | "all";
    sheetName: string;
  },
  "Events"
>({
  id: "File Processor",
  type: QonduitResourceTypes.Source,
  category: "trigger",
  color: "#329922",
  copywrite: "Upload file and read in batches",
  icon: "BiUpload",
  options: { files: {}, importFields: "all", sheetName: "" },
  outputs: ["Events"],
  schema: {
    type: "object",
    required: ["files"],
    properties: {
      files: {
        type: "object",
        additionalProperties: true,
        $ref: "#/definitions/FileMetadata",
      },
      importFields: {
        type: "array",
        items: { type: "string" },
        default: ["all"],
      },
      sheetName: { type: "string" },
    },
  },
});
