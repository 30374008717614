import "./definition-loader";
import { v4 } from "uuid";
import createStateManager from "./createStateManager";
import { StreamReference } from "./interfaces";
import { ProcessorInstance } from "./ProcessorInstance";
import { serializeStream } from "./serializeStream";
import { getProcessorDefinition } from "./definitions";
import { JSONSchema7Object } from "json-schema";

export type QonduitWorkflow = {
  id: string;
  instances: Record<string, ProcessorInstance>;
  title: string;
  desc: string;
  createAt: number;
};

const initialWorkflow: QonduitWorkflow = {
  id: "",
  instances: {},
  title: "",
  desc: "",
  createAt: new Date().getTime(),
};

const workflowBuilder = createStateManager(initialWorkflow, (Base) => {
  class WorkflowManager extends Base {
    load(workflow: QonduitWorkflow) {
      this.modify((draft) => {
        draft.id = workflow.id;
        draft.instances = workflow.instances;
        draft.title = workflow.title || "Untitled";
        draft.desc = workflow.desc || "";
      });
    }
    addProcessor(processor: ProcessorInstance) {
      this.modify((draft) => {
        draft.instances[processor.id] = processor;
      });
    }
    removeProcessor(id: string) {
      this.modify((draft) => {
        delete draft.instances[id];
        // clean any subscriptions to this instance
        for (const instance of Object.values(draft.instances)) {
          instance.subscriptions = instance.subscriptions.filter(
            (sub) => sub.instanceID !== id
          );
        }
      });
    }
    subscribe(id: string, stream: StreamReference) {
      this.modify((draft) => {
        const processor = draft.instances[id];
        processor.subscriptions.push(stream);
      });
    }
    unsubscribe(id: string, stream: StreamReference) {
      this.modify((draft) => {
        const processor = draft.instances[id];
        if (!processor) return;
        const serialized = serializeStream(stream);
        const streamIdx = processor.subscriptions.findIndex(
          (s) => serializeStream(s) === serialized
        );
        if (streamIdx > -1) {
          processor.subscriptions.splice(streamIdx, 1);
        }
      });
    }
    getInstance(id: string) {
      return this.select((state) => state.instances[id]);
    }
    getInstanceProcessorDefinition(id: string) {
      const instance = this.getInstance(id);
      return getProcessorDefinition(instance.processorID);
    }
    getInstanceStreamReferences(id: string): StreamReference[] {
      const workflowID = this.select((state) => state.id);
      const definition = this.getInstanceProcessorDefinition(id);
      return definition.outputs.map((output) => ({
        bucket: output,
        instanceID: id,
        workflowID,
      }));
    }
    modifyInstance(id: string, modifier: (draft: ProcessorInstance) => void) {
      this.modify((draft) => {
        modifier(draft.instances[id]);
      });
    }
    updateInstance(id: string, instance: ProcessorInstance<JSONSchema7Object>) {
      this.modify((draft) => {
        draft.instances[id] = instance;
      });
    }
  }
  return new WorkflowManager(initialWorkflow);
});

export default workflowBuilder;
