import { JSONSchema7Object } from "json-schema";
import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Source,
  "trigger",
  {
    secret: string;
    enableWebhook: boolean;
    payload: JSONSchema7Object;
    isPublic: boolean;
  },
  "Events"
>({
  id: "Manual Trigger",
  type: QonduitResourceTypes.Source,
  category: "trigger",
  color: "#329922",
  copywrite: "Trigger a processor manually via endpoint",
  icon: "BiTargetLock",
  options: {
    secret: "",
    enableWebhook: true,
    payload: { foo: 1 },
    isPublic: true,
  },
  outputs: ["Events"],
  schema: {
    type: "object",
    required: ["secret", "enableWebhook", "payload"],
    properties: {
      secret: { type: "string" },
      enableWebhook: { type: "boolean" },
      payload: { type: "object", required: [] },
      isPublic: { type: "boolean" },
    },
  },
});
