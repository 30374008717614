import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "logic and flow",
  { condition: string },
  "Yes" | "No"
>({
  id: "Condition",
  type: QonduitResourceTypes.Operator,
  category: "logic and flow",
  color: "#EEC200",
  copywrite: "Branching flow based on specified condition",
  icon: "BiGitBranch",
  options: { condition: "1 < 2" },
  schema: {
    type: "object",
    required: ["condition"],
    properties: { condition: { type: "string" } },
  },
  outputs: ["Yes", "No"],
});
