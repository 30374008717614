import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Text, VStack } from "@chakra-ui/layout";
import { ButtonGroup, Button, useToast } from "@chakra-ui/react";
import React from "react";
import QonduitContext from "../../../components/QonduitContext";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";

registerNode(
  definition,
  (props) => {
    return (
      <VStack spacing="5">
        <FormControl>
          <FormLabel>Condition</FormLabel>
          <Input
            backgroundColor="white"
            variant="outline"
            size="sm"
            {...props.formRef.register("options.condition")}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Condition
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.condition}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
