import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "data table",
  {
    from: "table" | "view";
    name: string;
    url: string;
    adminSecret: string;
    params: Record<string, any>;
    offset: number;
    limit: number;
    populate: string[];
  },
  "Success" | "Failed"
>({
  id: "Qore Data Reader",
  type: QonduitResourceTypes.Operator,
  category: "data table",
  color: "#929922",
  copywrite: "Read from Qore Data",
  icon: "BiCloudDownload",
  options: {
    from: "table",
    name: "users",
    url: "http://localhost:8080",
    adminSecret: "admin-secret",
    populate: [],
    params: {},
    offset: 0,
    limit: 100,
  },
  schema: {
    type: "object",
    required: ["from", "name", "adminSecret", "url", "populate"],
    properties: {
      from: { type: "string", enum: ["table", "view"] },
      name: { type: "string" },
      url: { type: "string" },
      adminSecret: { type: "string" },
      params: { type: "object", additionalProperties: true },
      offset: { type: "number", default: 0 },
      limit: { type: "number", default: 100 },
      populate: {
        type: "array",
        items: { type: "string" },
      },
    },
  },
  outputs: ["Success", "Failed"],
});
