import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "logic and flow",
  { function: string },
  "Executed" | "Error"
>({
  id: "Deno",
  type: QonduitResourceTypes.Operator,
  category: "logic and flow",
  color: "#4D93FF",
  copywrite: "Custom deno code",
  icon: "BiCodeCurly",
  options: {
    function: `//Import external module here
const main = async (input, variables) => {
  /* Start edit script */
  return input;
  /* End of edit script */
}`,
  },
  schema: {
    type: "object",
    required: ["function"],
    properties: { function: { type: "string" } },
  },
  outputs: ["Executed", "Error"],
});
