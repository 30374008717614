import React from "react";
import { createContext, useContext } from "use-context-selector";
import workflowBuilder from "./workflowBuilder";

export const context = createContext({
  build: (build: (builder: typeof workflowBuilder) => void) => {},
  state: workflowBuilder.manager.state,
});

export const useWorkflowBuilder = () => useContext(context);

export default function WorkflowBuilderProvider(props: {
  children: React.ReactNode;
}) {
  const builderRef = React.useRef(workflowBuilder);
  const [state, setState] = React.useState(builderRef.current.manager.state);
  const build = React.useCallback(
    (build: (build: typeof workflowBuilder) => void) => {
      build(builderRef.current);
      setState(builderRef.current.manager.state);
    },
    []
  );
  return (
    <context.Provider value={{ state, build }}>
      {props.children}
    </context.Provider>
  );
}
