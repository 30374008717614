import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import * as FeedloopIcon from "@feedloop/icon";
import { IconAlphabetical } from "@feedloop/icon";
import { Configuration, DefaultApi } from "@feedloop/qore-sdk";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { BiKey, BiLinkExternal, BiPlus, BiX } from "react-icons/bi";
import useSWR from "swr";
import env from "../../../utils/env";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";

const iconList = {
  auto: "IconFeedloop",
  boolean: "IconSwitchLeft",
  date: "IconCalendar",
  datetime: "IconCalendar",
  float: "IconNumerical",
  id: "IconNumerical",
  integer: "IconNumerical",
  json: "IconCode",
  password: "IconAlphabetical",
  array: "IconBranch",
  select: "IconSelect",
  role: "IconSelect",
  text: "IconAlphabetical",
  relations: "IconBranch",
};

registerNode(
  definition,
  (props) => {
    const table = props.formRef.watch("options.table");
    const fields = props.formRef.watch("options.fields");
    const { data: globalVar } = useSWR<{
      QORE_DATA_URL: string;
      QORE_DATA_ADMIN_SECRET: string;
    }>("context", async (params) => {
      const resp = await axios.get(
        `${env.NEXT_PUBLIC_API_ENDPOINT}/api/${params}`,
        {
          headers: {
            "x-qonduit-admin-secret": Cookies.get("qonduit-access-token"),
          },
        }
      );
      return resp.data.global;
    });

    const client = React.useMemo(
      () =>
        new DefaultApi(
          new Configuration({
            basePath: globalVar?.QORE_DATA_URL,
            apiKey: globalVar?.QORE_DATA_ADMIN_SECRET,
          })
        ),
      [globalVar]
    );

    const schema = useSWR(
      ["schema", globalVar?.QORE_DATA_URL, client],
      async () => {
        const resp = await client.getSchema();
        return typeof resp.data === "object" ? resp.data : undefined;
      }
    );

    const columns = React.useMemo(() => {
      const cols = schema?.data?.tables.find((t) => t.name === table);
      if (cols)
        return [
          ...cols?.columns,
          { name: "id", type: "id", references: undefined },
        ];
      return [];
    }, [table, schema?.data?.tables]);

    const handleAddFields = React.useCallback(
      (item) => {
        props.formRef.setValue("options.fields", [
          ...fields,
          { key: item, value: "" },
        ]);
      },
      [fields]
    );
    const selectedSet = React.useMemo(
      () => new Set(fields.map((f) => f.key)),
      [fields]
    );
    const unselectedFields = React.useMemo(() => {
      return columns.filter(
        (f) =>
          !selectedSet.has(f.name) &&
          (!f.references ||
            (f.references.type === "OneToMany" && f.references.origin))
      );
    }, [columns, selectedSet]);
    const handleDeleteFields = React.useCallback(
      (key) => {
        props.formRef.setValue(
          "options.fields",
          fields.filter((f) => f.key !== key)
        );
      },
      [fields]
    );

    React.useEffect(() => {
      const firstColumn = columns[0];
      if (!firstColumn || fields.length) return;
      props.formRef.setValue("options.fields", [
        { key: firstColumn.name, value: "{{foo}}" },
      ]);
    }, [columns, fields]);
    return (
      <VStack spacing="5" alignItems="normal">
        {/* <FormControl isRequired={true}>
          <FormLabel fontSize="14px">URL</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<BiLinkExternal />}
            />
            <Input
              size="sm"
              placeholder="qore data url"
              backgroundColor="white"
              {...props.formRef.register("options.url")}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired={true}>
          <FormLabel fontSize="14px">Admin Secret</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<BiKey />} />
            <Input
              size="sm"
              placeholder="qore data admin secret"
              backgroundColor="white"
              type="password"
              {...props.formRef.register("options.adminSecret")}
            />
          </InputGroup>
        </FormControl> */}
        <FormControl>
          <FormLabel>Target table</FormLabel>
          <Select
            // forces the select element to rebuild itself when the tables are avaiable
            key={schema?.data?.tables ? "0" : "1"}
            {...props.formRef.register("options.table")}
          >
            {schema?.data?.tables.map((table) => (
              <option value={table.name} key={table.name}>
                {table.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Text color="#6F809A">
          Other field stated below will be filled with default value
        </Text>
        <FormControl>
          <HStack width="100%">
            <FormLabel width="44%">Field</FormLabel>
            <FormLabel width="56%">Value</FormLabel>
          </HStack>
          <VStack>
            {fields.map((field, i) => (
              <HStack width="100%">
                <Input
                  value={field.key}
                  isDisabled
                  icon={<IconAlphabetical />}
                />
                <Input
                  {
                    // @ts-ignore
                    ...props.formRef.register(`options.fields.${i}.value`)
                  }
                />
                <Icon
                  as={BiX}
                  fontSize="20px"
                  cursor="pointer"
                  onClick={() => {
                    handleDeleteFields(field.key);
                  }}
                />
              </HStack>
            ))}
          </VStack>
          {!!unselectedFields.length && (
            <Menu>
              <MenuButton
                marginTop="8px"
                display="flex"
                alignContent="center"
                as={Button}
                alignSelf="flex-start"
                color="#0065FF"
                leftIcon={<BiPlus fontSize="18px" />}
                variant="unstyled"
                size="sm"
                _focus={{ borderWidth: "0px" }}
              >
                Add Value
              </MenuButton>
              <MenuList color="#001E4D" overflowX={"scroll"} maxH="150px">
                {unselectedFields
                  .filter((item) => Object.keys(iconList).includes(item.type))
                  .map((item) => (
                    <MenuItem
                      fontSize="14px"
                      icon={
                        <Icon
                          as={
                            // @ts-ignore
                            FeedloopIcon[
                              // @ts-ignore
                              iconList[
                                // @ts-ignore
                                item.references ? "relations" : item.type
                              ]
                            ]
                          }
                          fontSize="18px"
                        />
                      }
                      onClick={() => {
                        handleAddFields(item.name);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Table
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.table}
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            URL
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.url}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
