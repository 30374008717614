import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Flex, Text, VStack } from "@chakra-ui/layout";
import {
  Collapse,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Select,
  SlideFade,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  BiCalendar,
  BiGlobe,
  BiInfoCircle,
} from "react-icons/bi";
import { registerNode } from "../../ReactNodes";
import definition, {
  FrequencyVariant,
  getTimeWithoutDate,
  dateDiff,
} from "./definition";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NestedValue, UseFormReturn, useWatch } from "react-hook-form";
import { Custom, Daily, Hourly, Monthly, Weekly, Workdays } from "./frequency";
import * as cronjsMatcher from "@datasert/cronjs-matcher";
import { ProcessorInstance } from "../../ProcessorInstance";
import timezones from "./timezones.json";

interface Components {
  [key: string]: (props: {
    formRef: UseFormReturn<{
      options: NestedValue<{
        frequency: FrequencyVariant;
        isRepeat: boolean;
        startDate: string;
        endDate: string;
        script: string;
        tz: string;
      }>;
      metadata: NestedValue<ProcessorInstance["metadata"]>;
    }>;
    data: any;
  }) => JSX.Element;
}

const components: Components = {
  Hourly,
  Daily,
  Weekly,
  Workdays,
  Monthly,
  Custom,
};

registerNode(
  definition,
  (props) => {
    const [isCollapseFrequency, setIsCollapseFrequency] = React.useState(true);
    const data = props.formRef.watch();
    const startDateDisclosure = useDisclosure();
    const endDateDisclosure = useDisclosure();
    const start = useWatch({
      name: "options.startDate",
      control: props.formRef.control,
    });
    const end = useWatch({
      name: "options.endDate",
      control: props.formRef.control,
    });
    const startDate = React.useMemo(() => {
      return new Date(start);
    }, [start]);
    const endDate = React.useMemo(() => {
      return new Date(end);
    }, [end]);

    const frequencySelects = React.useMemo(() => {
      const oneDay = 24 * 60 * 60 * 1000;
      const start = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      const end = Date.UTC(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );
      const diffDays = Math.round(Math.abs((start - end) / oneDay));
      return definition.schema.properties.frequency.enum.filter(
        (item: { count: number }) => diffDays >= item.count
      );
    }, [startDate, endDate]);

    const setDate = React.useCallback(
      (date, type, isDateTime = false) => {
        const newDate = new Date(date);
        if (type === "start") {
          let newStartDate = new Date(newDate.setHours(0, 0));
          if (isDateTime) {
            newStartDate = date as Date;
          }
          props.formRef.setValue(
            "options.startDate",
            newStartDate.toISOString()
          );
        }
        let newEndDate = new Date(newDate.setHours(23, 59));
        props.formRef.setValue("options.endDate", newEndDate.toISOString());
        data.options.frequency.name !== "Custom interval..." &&
          props.formRef.setValue(
            "options.frequency",
            definition.schema.properties.frequency.enum[0]
          );
      },
      [startDate, endDate, frequencySelects, data.options.frequency]
    );

    const maxDate = React.useMemo(() => {
      const date = new Date(startDate);
      return new Date(date.setFullYear(date.getFullYear() + 1));
    }, []);

    const handleSelectFrequency = React.useCallback(
      (event) => {
        setIsCollapseFrequency(false);
        const selected = definition.schema.properties.frequency.enum.find(
          (item: { name: string }) => item.name === event.currentTarget.value
        );
        try{
          selected && props.formRef.setValue("options.frequency", selected);
          setTimeout(() => setIsCollapseFrequency(true), 300);  
        } catch (err){
          console.log(err)
        }
      },
      [data]
    );

    const frequencyMenu = React.useCallback(() => {
      const frequency: string = data.options.frequency.name.split(" ")[0];
      const FrequencyComponent = components[frequency];
      return (
        <FrequencyComponent
          formRef={props.formRef}
          data={data.options.frequency}
        />
      );
    }, [
      data.options.frequency,
      props.formRef,
      data.options.frequency.template,
    ]);

    const estimationScheduler = React.useMemo(() => {
      let script = data.options.frequency.template;
      let convertStartDate: { [key: string]: number | string } = {
        m: "*",
        h: startDate.getUTCHours(),
        d: startDate.getUTCDate(),
        D: startDate.getUTCDay(),
        M: startDate.getUTCMonth() + 1,
        y: startDate.getUTCFullYear(),
      };
      let convertEndDate: { [key: string]: number } = {
        h: endDate.getUTCHours(),
        d: endDate.getUTCDate(),
        M: endDate.getUTCMonth() + 1,
      };
      switch (data.options.frequency.name) {
        case "Monthly":
          convertStartDate.d = data.options.frequency.date;
          break;
        case "Hourly":
          convertStartDate.h = data.options.frequency.isRange
            ? startDate.getUTCHours()
            : "*";
          break;
        case "Custom interval...":
          convertStartDate.h = `${
            data.options.frequency.type === "hours" ||
            data.options.frequency.type === "minutes"
              ? "*"
              : convertStartDate.h
          }`;
          convertStartDate.y = `${
            convertStartDate.y !== endDate.getUTCFullYear()
              ? `${convertStartDate.y}-${endDate.getUTCFullYear()}`
              : `${convertStartDate.y}`
          }`;
          break;
      }
      Object.keys(convertStartDate).forEach((el) => {
        script = script.replace(`${el}`, `${convertStartDate[el]}`);
      });
      if (
        data.options.frequency.name === "Hourly" ||
        data.options.frequency.name === "Custom interval..."
      ) {
        if (data.options.frequency.isRange) {
          convertEndDate.h =
            data.options.frequency.name === "Custom interval..." &&
            convertStartDate.h === convertEndDate.h &&
            data.options.frequency.type === "minutes"
              ? convertEndDate.h + 1
              : convertEndDate.h;
          script = data.options.frequency.template;
          Object.keys(convertStartDate).forEach((el) => {
            script = script.replace(
              `${el}`,
              `${convertStartDate[el]}${
                convertEndDate[el] ? `-${convertEndDate[el]}` : ""
              }`
            );
          });
        }
        script =
          data.options.frequency.name === "Custom interval..."
            ? script.replace(
                "c",
                `${
                  data.options.frequency.value
                    ? data.options.frequency.value
                    : "1"
                }`
              )
            : script;
      }
      const startAt = new Date(
        new Date(data.options.startDate).setSeconds(0)
      ).toJSON();
      const endAt = new Date(
        new Date(data.options.endDate).setSeconds(1)
      ).toJSON();

      if (data.options.frequency.name === "Custom interval...") {
        switch (data.options.frequency.type) {
          case "minutes":
            script = `*/${
              data.options.frequency.value ? data.options.frequency.value : "1"
            } * * * *`;
            break;
          case "hours":
            script = `0 */${
              data.options.frequency.value ? data.options.frequency.value : "1"
            } * * *`;
            break;
          case "days":
          case "weeks":
          case "months":
            try{
              props.formRef.setValue("options.frequency.isRange", false);
            } catch (err){
              console.log(err)            
            }
            const customScripts = script.split(" ");
            customScripts[0] = `${(new Date(data.options.startDate)).getMinutes()}`;
            script = customScripts.join(" ");            
            break;        }
      } else {
        script = "0" + script.substring(1);
      }
      props.formRef.setValue("options.script", script);

      try {
        let futureMatches = cronjsMatcher.getFutureMatches(
          data.options.script,
          { startAt, endAt, matchCount: 10000, timezone: "Etc/UTC" }
        );

        if (data.options.frequency.isRange) {
          futureMatches = futureMatches.filter((date_string: string) => {
            let date = new Date(date_string);
            return (
              getTimeWithoutDate(date) >=
                getTimeWithoutDate(new Date(startAt)) &&
              getTimeWithoutDate(date) <= getTimeWithoutDate(new Date(endAt))
            );
          });
        }

        futureMatches = futureMatches.filter((date_string: string) => {
          let date = new Date(date_string);
          switch (data.options.frequency.name) {
            case "Workdays (Mon-Fri)":
              return date.getDay() != 6 && date.getDay() != 0;
            case "Weekly":
              return date.getDay() == data.options.frequency.day;
            case "Monthly":
              return date.getDate() == data.options.frequency.date;
            case "Custom interval...":
              if (data.options.frequency.type == "weeks"){
                return date.getDay() == data.options.frequency.day && ((dateDiff(date, new Date(data.options.startDate), "weeks")) % data.options.frequency.value == 0)
              } else if (data.options.frequency.type == "months"){
                return date.getDate() == data.options.frequency.date && (dateDiff(date, new Date(data.options.startDate), "months") % data.options.frequency.value == 0)
              } else if (data.options.frequency.type == "days"){
                return dateDiff(date, new Date(data.options.startDate), "days") % data.options.frequency.value == 0
              } else{
                return true
              }
          }
          return true;
        });

        return `${futureMatches.length}`;
      } catch (error) {
        console.log(error);
      }
    }, [
      data.options.frequency,
      data.options.script,
      startDate,
      endDate,
      data.options.frequency.type,
      data.options.frequency.template,
      data.options.frequency.value,
      data.options.tz,
    ]);

    const minDate = React.useMemo(() => {
      const date = new Date();
      date.setHours(0);
      return date;
    }, []);
    return (
      <VStack spacing="5" alignItems="normal">
        <FormControl display="flex" alignItems="center">
          <Switch
            size="md"
            id="is-repeat"
            {...props.formRef.register("options.isRepeat")}
          />
          <FormLabel htmlFor="is-repeat" ml="16px" mb="0">
            Repeat trigger
          </FormLabel>
        </FormControl>
        <Collapse
          style={{ width: "100%" }}
          in={data.options.isRepeat}
          unmountOnExit
        >
          <VStack spacing="5">
            <HStack width="100%">
              <FormControl width="49%">
                <FormLabel>Starts at</FormLabel>
                <Menu
                  onOpen={startDateDisclosure.onOpen}
                  onClose={startDateDisclosure.onClose}
                  isOpen={startDateDisclosure.isOpen}
                >
                  <MenuButton
                    as={Button}
                    width="100%"
                    size="sm"
                    fontWeight="400"
                    isActive={startDateDisclosure.isOpen}
                    rightIcon={<BiCalendar />}
                    textAlign="start"
                  >
                    {startDate.toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </MenuButton>
                  <MenuList paddingTop="0px" paddingBottom="0px" height="240px">
                    <DatePicker
                      disabledKeyboardNavigation
                      selected={startDate}
                      onChange={(date) => {
                        setDate(date, "start");
                        startDateDisclosure.onClose();
                        endDateDisclosure.onOpen();
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      inline
                    />
                  </MenuList>
                </Menu>
              </FormControl>
              <Text alignSelf="flex-end" pb="2" width="2%">
                -
              </Text>
              <FormControl width="49%">
                <FormLabel>Finish at</FormLabel>
                <Menu
                  onClose={endDateDisclosure.onClose}
                  onOpen={endDateDisclosure.onOpen}
                  isOpen={endDateDisclosure.isOpen}
                >
                  <MenuButton
                    isActive={endDateDisclosure.isOpen}
                    width="100%"
                    size="sm"
                    fontWeight="400"
                    as={Button}
                    rightIcon={<BiCalendar />}
                    textAlign="start"
                  >
                    {endDate.toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </MenuButton>
                  <MenuList paddingTop="0px" paddingBottom="0px" height="240px">
                    <DatePicker
                      disabledKeyboardNavigation
                      selected={endDate}
                      onChange={(date) => {
                        setDate(date, "end");
                        endDateDisclosure.onClose();
                      }}
                      minDate={startDate}
                      maxDate={maxDate}
                      inline
                    />
                  </MenuList>
                </Menu>
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel>Frequency</FormLabel>
              <Select
                value={data.options.frequency.name}
                onChange={handleSelectFrequency}
              >
                {frequencySelects.map(
                  (
                    option: { name: string },
                    i: React.Key | null | undefined
                  ) => (
                    <option value={option.name} key={i}>
                      {option.name}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
            <SlideFade
              style={{ width: "100%" }}
              in={isCollapseFrequency}
              offsetY="20px"
            >
              <VStack
                bgColor="#F9F9FA"
                border="1px solid #E6E9EC"
                padding="16px"
                spacing="4"
                width="100%"
                alignItems="flex-start"
              >
                {frequencyMenu()}
                <Text color="#6F809A">{data.options.frequency.desc}</Text>
              </VStack>
            </SlideFade>
            <HStack paddingBottom="20px" alignSelf="flex-start">
              <BiInfoCircle />
              <Text>
                By your setting, this trigger will run approx.{" "}
                <span style={{ fontWeight: "bold" }}>
                  {estimationScheduler} times
                </span>
              </Text>
            </HStack>
          </VStack>
        </Collapse>
        <Collapse
          style={{ width: "100%" }}
          in={!data.options.isRepeat}
          unmountOnExit
        >
          <VStack>
            <FormControl>
              <FormLabel>Trigger date</FormLabel>
              <Menu>
                {({ isOpen, onClose }) => (
                  <>
                    <MenuButton
                      size="sm"
                      isActive={isOpen}
                      as={Button}
                      width="100%"
                      fontWeight="400"
                      backgroundColor="white"
                      rightIcon={<BiCalendar />}
                      textAlign="start"
                    >
                      {startDate.toLocaleDateString("en-us", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </MenuButton>
                    <MenuList
                      paddingTop="0px"
                      paddingBottom="0px"
                      height="240px"
                    >
                      <DatePicker
                        disabledKeyboardNavigation
                        showTimeSelect
                        inline
                        selected={startDate}
                        minDate={new Date()}
                        maxDate={maxDate}
                        onChange={(date) => {
                          setDate(date, "start", true);
                          onClose();
                        }}
                      />
                    </MenuList>
                  </>
                )}
              </Menu>
            </FormControl>
          </VStack>
        </Collapse>
        <Portal containerRef={props.portalRef}>
          <FormControl width="250px">
            <Select
              icon={<BiGlobe />}
              size="md"
              fontSize="14px"
              color="#6F809A"
              {...props.formRef.register("options.tz")}
            >
              {timezones.map((item, i) => (
                <option value={item.value} key={i}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </Portal>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Schedule
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.script}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
