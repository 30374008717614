import { UseDisclosureProps } from "@chakra-ui/hooks";
import { Box, Text, VStack } from "@chakra-ui/layout";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React from "react";
import { Edge, Elements, useStoreState } from "react-flow-renderer";
import { CreateWorkflowProcessorRequestSubscriptionsInner } from "../client";
import { getDefinitions } from "../server/definitions";
import {
  buildProcessorInstance,
  deserializeStream,
  QonduitProcessorDefinition,
} from "../server/interfaces";
import { useWorkflowBuilder } from "../server/WorkflowBuilderProvider";
import { useClient } from "../utils/QonduitClient";
import { InstanceData, SubscriptionData } from "../utils/QonduitConverter";
import QonduitContext from "./QonduitContext";

const Processors = ({
  addProcessorDisclosure,
  type,
  output,
  position,
  category,
}: {
  addProcessorDisclosure: UseDisclosureProps;
  type: { button?: string; instance?: string };
  output: string;
  position?: string;
  category: string;
}) => {
  const selectedElements: Elements<InstanceData> | null = useStoreState(
    (state) => state.selectedElements
  );
  const qonduitActions = QonduitContext.useActions();
  const client = useClient();
  const edges: Edge<SubscriptionData>[] = useStoreState((state) => state.edges);
  const { build, state } = useWorkflowBuilder();
  const { onClose, isOpen } = addProcessorDisclosure;

  // TODO: Uncomment when add serial processor has been provided on backend
  // const handleAddProcessorSerial = React.useCallback(
  //   (definition: QonduitProcessorDefinition) => {
  //     build(async (builder) => {
  //       const [workflowID, sourceInstanceID] =
  //         selectedElements![0]?.id.split("_");
  //       const subscriptions = edges.filter((edge) =>
  //         position === "top"
  //           ? edge.target.split("_")[1] === sourceInstanceID
  //           : edge.source.split("_")[1] === sourceInstanceID &&
  //             edge.sourceHandle === output
  //       );

  //       // remove subscription edge on previous instance
  //       subscriptions.forEach((el) => {
  //         const [id, subscriptionStr] = el.id.split("_subscribes_");
  //         builder.manager.unsubscribe(id, deserializeStream(subscriptionStr));
  //       });
  //       // add new instance
  //       builder.manager.addProcessor(buildProcessorInstance(definition));
  //       const newInstances = Object.values(builder.manager.state.instances);
  //       const targetInstance = newInstances[newInstances.length - 1];
  //       const targetSubscription: CreateWorkflowProcessorRequestSubscriptionsInner[] =
  //         [
  //           {
  //             bucket:
  //               position === "bottom" ? output : subscriptions[0].sourceHandle!,
  //             instanceID:
  //               position === "bottom"
  //                 ? sourceInstanceID
  //                 : subscriptions[0].source.split("_")[1],
  //             workflowID,
  //           },
  //         ];
  //       // set edge
  //       subscriptions.forEach((el) => {
  //         const [_, childrenID] = el.target.split("_");
  //         builder.manager.subscribe(childrenID, {
  //           bucket: definition.outputs[0],
  //           instanceID: targetInstance.id,
  //           workflowID,
  //         });
  //       });
  //       builder.manager.subscribe(targetInstance.id, targetSubscription[0]);
  //     });
  //     qonduitActions.editStatusWorkflow("true");
  //     onClose!();
  //   },
  //   [build, selectedElements, state, edges]
  // );

  const handleAddProcessorParallel = React.useCallback(
    (definition: QonduitProcessorDefinition) => {
      build((builder) => {
        let [workflowID, sourceInstanceID] =
          selectedElements![0]?.id.split("_");
        builder.manager.addProcessor(buildProcessorInstance(definition));
        const newElements = Object.values(builder.manager.state.instances);
        const targetInstance = newElements[newElements.length - 1];
        if (output !== "startNode") {
          builder.manager.subscribe(targetInstance.id, {
            bucket: output,
            instanceID: sourceInstanceID,
            workflowID,
          });
        }
      });
      qonduitActions.editStatusWorkflow("true");
      onClose!();
    },
    [build, selectedElements]
  );

  const definitions = React.useMemo(
    () =>
      type?.instance
        ? Object.values(getDefinitions()).filter(
            (el) => el.type === type.instance
          )
        : Object.values(getDefinitions()).filter((el) => el.type !== "source"),
    []
  );
  return (
    <VStack overflowY={"auto"} p={2}>
      {definitions
        .filter((definition) => definition.category === category.toLowerCase())
        .map((definition) => (
          <Box
            onClick={() => {
              // TODO: Uncomment when add serial processor has been provided on backend
              // if (type.button === "parallel")
              //   handleAddProcessorParallel(definition);
              // else if (type.button === "serial")
              //   handleAddProcessorSerial(definition);

              /* TODO: Delete this function when add serial processor has been provided */
              handleAddProcessorParallel(definition);
            }}
            padding={4}
            width="100%"
            bg="white"
            _hover={{
              filter: `drop-shadow(0px 0px 2px #001E4D)`,
            }}
            borderRadius={4}
            cursor="pointer"
            key={definition.id}
          >
            <Text
              color="#001E4D"
              fontSize="14px"
              lineHeight="20px"
              fontWeight="600"
            >
              {definition.id}
            </Text>
            <Text
              color="#A6B0C1"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
            >
              {definition.copywrite}
            </Text>
          </Box>
        ))}
    </VStack>
  );
};

const Categories = ({
  addProcessorDisclosure,
  type,
  output,
  position,
}: {
  addProcessorDisclosure: UseDisclosureProps;
  type: { button?: string; instance?: string };
  output: string;
  position?: string;
}) => {
  const categories = [
    "trigger",
    "logic and flow",
    "operation",
    "API",
    "integration",
    "data table",
  ];

  const processorCategories = React.useMemo(
    () => categories.filter((category) => category !== "trigger"),
    [categories]
  );

  if (type?.instance === "source") {
    return (
      <Processors
        addProcessorDisclosure={addProcessorDisclosure}
        type={type}
        output={output}
        position={position}
        category="trigger"
      ></Processors>
    );
  }
  return (
    <VStack overflowY={"auto"}>
      <Accordion allowMultiple w="full">
        {processorCategories?.map((category, i) => {
          return (
            <AccordionItem>
              <h2>
                <AccordionButton
                  bgColor="white"
                  _focus={{ borderWidth: "0px" }}
                >
                  <Box flex="1" textAlign="left" textTransform="capitalize">
                    {category}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={0}>
                <Processors
                  addProcessorDisclosure={addProcessorDisclosure}
                  type={type}
                  output={output}
                  position={position}
                  category={category}
                ></Processors>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </VStack>
  );
};

export default function AddProcessorDrawer({
  addProcessorDisclosure,
  type,
  output,
  position,
}: {
  addProcessorDisclosure: UseDisclosureProps;
  type: { button?: string; instance?: string };
  output: string;
  position?: string;
}) {
  const { onClose, isOpen } = addProcessorDisclosure;
  return (
    <div>
      <Drawer
        placement="left"
        onClose={onClose!}
        isOpen={isOpen!}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" fontSize={"lg"}>
            {type?.instance === "source" ? "Add trigger source" : "Add process"}
          </DrawerHeader>
          <DrawerBody bg="gray.200" paddingInline={"4"}>
            <Categories
              addProcessorDisclosure={addProcessorDisclosure}
              type={type}
              output={output}
              position={position}
            ></Categories>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
