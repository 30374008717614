import "../server/definition-loader";
import { Box, Divider, Spacer, Text } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import {
  Edge,
  Handle,
  Position,
  useStoreState,
  useZoomPanHelper,
} from "react-flow-renderer";
import { deserializeStream } from "./interfaces";
import { ProcessorInstance } from "./ProcessorInstance";
import {
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
  Tooltip,
  useToast,
  Collapse,
  ModalFooter,
  ButtonGroup,
  Button,
  UseDisclosureProps,
  UseDisclosureReturn,
  IconButton,
  Icon,
  FormControl,
  Input,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import * as Bi from "react-icons/bi";
import { IconArrowDown } from "@feedloop/icon";
import useProcessorStats from "./useProcessorStats";
import AddProcessorMenu from "../components/menu/AddProcessorMenu";
import { SubscriptionData } from "../utils/QonduitConverter";
import QonduitContext from "../components/QonduitContext";
import { useWorkflowBuilder } from "./WorkflowBuilderProvider";
import { useClient } from "../utils/QonduitClient";
import { NestedValue, UseFormReturn } from "react-hook-form";
import { JSONSchema7Object } from "json-schema";
import useSWR from "swr";
import WorkflowDecisionModal from "../components/modal/WorkflowDecisionModal";
import { getProcessorDefinition } from "./definitions";
import _ from "lodash";
import Editor from "@monaco-editor/react";
import { isValidCron } from "cron-validator";
import { useRouter } from "next/dist/client/router";

function hexToRgbA(hex: string, isSelected: boolean) {
  let c: string[] = [];
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    let rgb: any = "0x" + c.join("");
    return (
      "rgba(" +
      [(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255].join(",") +
      `,${isSelected ? "0.16" : "0.08"})`
    );
  }
}

export default function NodeWrapper<
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object
>(props: {
  settingModal?: React.ReactNode;
  expandedContent: React.ReactNode;
  modifyMetadata: (
    modifier: (draft: ProcessorInstance["metadata"]) => void
  ) => void;
  modifyOptions: (
    modifier: (draft: ProcessorInstance<TSchemaObject>["options"]) => void
  ) => void;
  formRef: UseFormReturn<{
    options: NestedValue<TSchemaObject>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  optionsValue: TSchemaObject;
  portalRef: React.MutableRefObject<any>;
  setOptions: React.Dispatch<React.SetStateAction<TSchemaObject>>;
  nextmodalContentDisclosure: UseDisclosureReturn;
  modalContentDisclosure: UseDisclosureReturn;
  modalBucketContentDisclosure: UseDisclosureReturn;
  instance: ProcessorInstance;
}) {
  const [isEditName, setIsEditName] = React.useState(false);
  const [currentName, setCurrentName] = React.useState(
    props.instance.metadata.name
  );
  const selectedElements: any = useStoreState(
    (state) => state.selectedElements
  );
  const { setCenter } = useZoomPanHelper();
  const edges: Edge<SubscriptionData>[] = useStoreState((state) => state.edges);
  const toast = useToast();
  const qonduitActions = QonduitContext.useActions();
  const tracingID = QonduitContext.useSelectState((state) => state.tracingID);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const stats = useProcessorStats(props.instance.id);
  const collapseDisclosure = useDisclosure();
  const {
    build,
    state: { id: workflowID },
  } = useWorkflowBuilder();
  const client = useClient();
  const stopWorkflowDisclosure = useDisclosure();
  const [processorOutputData, setProcessorOutputData] = React.useState([]);
  const [processorOutputPages, setProcessorOutputPages] = React.useState(0);
  const [selectedBucket, setSelectedBucket] = React.useState("");
  const [selectedPage, setSelectedPage] = React.useState(0);

  const tracingSelectedEdgeID = QonduitContext.useSelectState(
    (state) => state.tracingSelectedEdgeID
  );

  const handleCenterSelectedID = React.useCallback(() => {
    let { x, y } = selectedElements[0].position;
    y = y + 200;
    x = x - 200;
    const zoom = 0.6;
    setCenter(x, y, zoom);
  }, [selectedElements, tracingID]);

  const onSubmit = React.useCallback(
    (data) => {
      if (data.options?.isValid === false) {
        toast({
          description: "Failed! Invalid node input",
          isClosable: true,
          status: "error",
        });
        return;
      }
      props.modifyMetadata((draft) => {
        draft.name = currentName;
      });
      props.modifyOptions((draft) => {
        const keys = Object.keys(data.options);
        keys.forEach((key: string) => {
          // @ts-ignore
          draft[key] = data.options[key];
        });
      });
      qonduitActions.editStatusWorkflow("true");
      props.modalContentDisclosure?.onClose();
      props.formRef.reset({
        metadata: data.metadata,
        options: data.options,
      });
      toast({
        description: "Success! your block is updated",
        isClosable: true,
        status: "success",
      });
    },
    [currentName]
  );

  React.useEffect(() => {
    tracingID.length &&
      selectedElements?.length &&
      selectedElements[0].position &&
      handleCenterSelectedID();
  }, [tracingID, selectedElements]);

  const sourceElementConnect = QonduitContext.useSelectState(
    (state) => state.sourceElement
  );

  const isSelected = React.useMemo(
    () =>
      !!selectedElements?.find((el: { id: string }) =>
        el.id.endsWith(props.instance.id)
      ),
    [selectedElements, props.instance.id, tracingID]
  );

  const hasSubscription = React.useMemo(
    () => props.instance.subscriptions.length,
    [props.instance.subscriptions]
  );

  const hasSiblings = React.useMemo(() => {
    if (props.instance.subscriptions.length > 1) return true;
    const siblings = edges.filter(
      (edge) =>
        edge.data?.subscription?.instanceID ===
        props.instance.subscriptions[0]?.instanceID
    );
    return siblings.length > 1;
  }, [props.instance.subscriptions, edges]);

  const processorDefinition = React.useMemo(() => {
    return getProcessorDefinition(props.instance.processorID);
  }, [props.instance.processorID]);

  const ProcessorIcon = Bi[processorDefinition.icon];

  const rgbShadow = React.useMemo(
    () => hexToRgbA(processorDefinition.color, isSelected),
    [processorDefinition.color, isSelected]
  );
  const isSimilarEdge = React.useMemo(() => {
    const [_, sourceInstanceID] = sourceElementConnect.id.split("_");
    if (
      sourceElementConnect.position === "top" &&
      sourceElementConnect.subscriptions.length
    ) {
      if (
        sourceElementConnect.subscriptions.find(
          (sub) => sub.instanceID === props.instance.id
        )
      )
        return true;
    } else if (sourceElementConnect.position === "bottom") {
      if (
        edges.find(
          (edge) =>
            edge.source.split("_")[1] === sourceInstanceID &&
            edge.target.split("_")[1] === props.instance.id &&
            edge.sourceHandle === sourceElementConnect.output
        )
      )
        return true;
    }
    return false;
  }, [sourceElementConnect, props.instance.id, edges]);

  const processorStyle = React.useMemo(() => {
    if (sourceElementConnect.id.length) {
      if (sourceElementConnect.id.split("_")[1] === props.instance.id) {
        return css({
          border: `1px solid ${processorDefinition.color}`,
          borderRadius: "4px",
          background: "white",
        });
      } else if (processorDefinition.type === "source" || isSimilarEdge) {
        return css({
          background: "white",
          opacity: 0.3,
          cursor: "not-allowed",
        });
      } else {
        return css({
          "&:hover": {
            border: `1px solid ${processorDefinition.color}`,
            borderRadius: "4px",
          },
          background: "white",
        });
      }
    } else if (tracingID.length) {
      if (!isSelected && selectedElements?.length) {
        return css({
          background: "white",
          opacity: 0.3,
        });
      }
    }
    return css({
      filter: `drop-shadow(0px 4px 16px ${rgbShadow})`,
      borderRadius: "4px",
      background: "white",
      overflow: "hidden",
      width: "320px",
      border: `${
        isSelected
          ? `1px solid ${processorDefinition.color}`
          : "1px solid white"
      }`,
    });
  }, [
    processorDefinition.color,
    isSelected,
    sourceElementConnect,
    tracingID,
    selectedElements,
  ]);

  const handleConnect = React.useCallback(() => {
    const [workflowID, sourceInstanceID] = sourceElementConnect.id.split("_");
    qonduitActions.traceLog("");
    if (
      !sourceElementConnect.id.length ||
      processorDefinition.type === "source" ||
      isSimilarEdge ||
      props.instance.id === sourceInstanceID
    )
      return;
    build((builder) => {
      builder.manager.subscribe(
        sourceElementConnect.position === "bottom"
          ? props.instance.id
          : sourceInstanceID,
        {
          bucket:
            sourceElementConnect.position === "bottom"
              ? sourceElementConnect.output
              : processorDefinition.outputs[0],
          instanceID:
            sourceElementConnect.position === "bottom"
              ? sourceInstanceID
              : props.instance.id,
          workflowID,
        }
      );
    });
    toast.closeAll();
  }, [sourceElementConnect, selectedElements, edges]);

  const disableHandlerClass = React.useMemo(() => {
    return (sourceElementConnect.id.length &&
      processorDefinition.type === "source") ||
      props.instance.id === sourceElementConnect.id.split("_")[1] ||
      isSimilarEdge
      ? "disabled"
      : "";
  }, [sourceElementConnect, selectedElements, edges]);

  const handleSelectedEdgeStroke = React.useCallback(
    (output) => {
      return tracingID.length &&
        tracingSelectedEdgeID.length &&
        selectedElements?.length &&
        props.instance.id === tracingSelectedEdgeID.split("_")[1] &&
        output === tracingSelectedEdgeID.split("_")[2]
        ? "#0065FF"
        : "#b2b9c0";
    },
    [selectedElements, tracingSelectedEdgeID]
  );

  const isVisibleProcessorOption = React.useMemo(() => {
    return isSelected && !sourceElementConnect.id ? "block" : "none";
  }, [isSelected]);

  const handleRemoveProcessor = React.useCallback(() => {
    build(async (builder) => {
      const [workflowID, instanceID] = selectedElements![0]?.id.split("_");
      const childrens = edges.filter(
        (edge) => edge.source === selectedElements![0]?.id
      );
      builder.manager.removeProcessor(instanceID);
      childrens.length &&
        childrens.forEach((children) => {
          const [id, subscriptionStr] = children.id.split("_subscribes_");
          builder.manager.unsubscribe(id, deserializeStream(subscriptionStr));
        });
      await client.deleteWorkflowProcessor(instanceID, workflowID);
    });
  }, [client, selectedElements, edges]);

  const isVisibleConnectProcessorTooltip = React.useMemo(() => {
    return sourceElementConnect.id.length &&
      !(processorDefinition.type === "source" || isSimilarEdge)
      ? "inherit"
      : "none";
  }, [sourceElementConnect, processorDefinition, isSimilarEdge]);

  const collapseStyle = React.useMemo(() => {
    return collapseDisclosure.isOpen
      ? {
          transform: "rotate(-180deg)",
          transition: "transform 0.2s ease 0s",
          transformOrigin: "center center",
        }
      : {
          transition: "transform 0.2s ease 0s",
          transformOrigin: "center center",
        };
  }, [collapseDisclosure]);

  const status = useSWR([client, "status", workflowID], async () => {
    if (!workflowID) return false;
    const resp = await client.getWorkflowStatus(workflowID);
    return !!resp.data.isActive;
  });
  const handleStopWorkflow = React.useCallback(async () => {
    await client.stopWorkflow(workflowID);
    status.revalidate();
    stopWorkflowDisclosure.onClose();
    props.modalContentDisclosure.onOpen();
  }, [client]);

  useSWR(
    [props.instance.id, props.modalContentDisclosure.isOpen],
    async function loadLatestInstanceOnOpen() {
      if (!props.modalContentDisclosure.isOpen) return;
      const resp = await client.getProcessor(props.instance.id);
      const instance = resp.data as unknown as ProcessorInstance;
      if (!instance) return;
      build((builder) => {
        builder.manager.modifyInstance(props.instance.id, (draft) => {
          draft.metadata = instance.metadata;
          draft.options = instance.options;
        });
      });
      props.formRef.reset({
        metadata: instance.metadata,
        // @ts-ignore
        options: instance.options,
      });
    },
    {
      revalidateOnFocus: false,
    }
  );

  const settingModalStyle = React.useMemo(() => {
    return {
      backgroundColor: "#F9F9FA",
      border: "1px solid #E6E9EC",
      borderRadius: "4px",
      padding: "16px",
    };
  }, []);

  const disableSave = React.useMemo(() => {
    const { options } = props.formRef.getValues();
    if (props.instance.processorID === "File Processor") {
      if (_.isEmpty(options.files)) return true;
    } else if (props.instance.processorID === "Scheduler") {
      if (!isValidCron(_.toString(options.script ? options.script : "")))
        return true;
    }
    return false;
  }, [props.formRef.getValues()]);

  const handleRename = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >((e) => {
    setCurrentName(e.target.value);
  }, []);

  const handleToggleRename = React.useCallback(() => {
    if (currentName == props.instance.metadata.name) {
      setCurrentName(props.instance.metadata.name);
    }
    setIsEditName(!isEditName);
  }, [isEditName]);

  const pagination = 5;
  const boundPagination = 2;

  const generateNumPages = React.useCallback(() => {
    const numPages = [];
    const leftBound =
      selectedPage - boundPagination > 1 ? selectedPage - boundPagination : 1;
    const rightBound =
      selectedPage + boundPagination > processorOutputPages
        ? processorOutputPages
        : selectedPage + boundPagination;
    for (let i = leftBound; i < rightBound + 1; i++) {
      numPages.push(i);
    }
    return numPages;
  }, [selectedPage, processorOutputPages]);

  const generateCurrPage = React.useCallback(
    async (processorID: string) => {
      if (selectedPage == 0) {
        return;
      }
      const limit = pagination;
      const offset = (selectedPage - 1) * limit;
      const resp = (await client.getBucketLogs(
        processorID,
        selectedBucket,
        limit,
        offset
      )) as unknown as any;
      if (resp === undefined) {
        return;
      }
      setProcessorOutputData(resp.data);
    },
    [client, selectedBucket, selectedPage]
  );

  useEffect(() => {
    generateCurrPage(props.instance.id);
  }, [selectedBucket, selectedPage]);

  const handleClickNext = React.useCallback(() => {
    const next =
      selectedPage + 1 > processorOutputPages + 1
        ? selectedPage
        : selectedPage + 1;
    setSelectedPage(next);
  }, [selectedPage]);

  const handleClickPrevious = React.useCallback(() => {
    const prev = selectedPage - 1 == 0 ? selectedPage : selectedPage - 1;
    setSelectedPage(prev);
  }, [selectedPage]);

  const handleClickTrace = React.useCallback((tracingID: string) => {
    qonduitActions.trace("");
    qonduitActions.trace(tracingID);
    props.modalBucketContentDisclosure.onClose();
  }, []);

  return (
    <Box>
      <Flex
        top={
          hasSubscription && hasSiblings
            ? "-4.5rem"
            : hasSubscription && !hasSiblings
            ? "-2rem"
            : -50
        }
        width="320px"
        justifyContent="center"
      >
        <VStack>
          {/* TODO: Uncomment when add serial processor has been provided on
          backend
          {hasSubscription && (
            <>
              {hasSiblings && (
                <AddProcessorMenu
                  position="top"
                  output="Input"
                  instanceID={props.instance.id}
                />
              )}
            </>
          )} */}
          <Handle
            position={Position.Top}
            type="target"
            isConnectable={false}
            className={disableHandlerClass}
            style={{
              borderRadius: "4px",
              border: "none",
              width: "auto",
              height: "auto",
              padding: "2px 10px",
              background: "white",
              filter: "drop-shadow( 0px 2px 8px rgba(0, 30, 77, 0.08))",
              fontSize: "13px",
              fontWeight: 600,
              color: "black",
              position: "static",
              transform: "translate(0%)",
            }}
          >
            {`Input [${stats.data?.processed || 0}]`}
          </Handle>
        </VStack>
      </Flex>
      <Tooltip
        hasArrow
        display={isVisibleConnectProcessorTooltip}
        label="Connect to this block"
        bg="#001E4D"
        fontSize="12px"
        isOpen={isTooltipOpen}
      >
        <Box
          css={processorStyle}
          onClick={handleConnect}
          onMouseEnter={() => setIsTooltipOpen(true)}
          onMouseLeave={() => setIsTooltipOpen(false)}
        >
          <HStack
            className="drag-handle"
            padding="6px 12px"
            background={processorDefinition.color}
            borderTopRadius={4}
            color="white"
          >
            <Icon as={ProcessorIcon} />
            <Text
              style={{
                width: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {processorDefinition.id}
            </Text>
            <Spacer />
            <Box display={isVisibleProcessorOption}>
              <HStack spacing={2}>
                {processorDefinition.id === "Manual Trigger" && (
                  <IconButton
                    cursor="pointer"
                    variant="ghost"
                    aria-label="start"
                    colorScheme="whiteAlpha"
                    size="xs"
                    as={Bi.BiPlay}
                    onClick={() => {
                      props.modalContentDisclosure.onOpen();
                      props.nextmodalContentDisclosure.onOpen();
                    }}
                  />
                )}
                <Icon
                  cursor="pointer"
                  fontSize={20}
                  as={Bi.BiCog}
                  onClick={props.modalContentDisclosure.onOpen}
                />
                <WorkflowDecisionModal
                  modalDisclosure={stopWorkflowDisclosure}
                  modalType="stop"
                  submit={handleStopWorkflow}
                  cancelAction={stopWorkflowDisclosure.onClose}
                />
                <Modal
                  isOpen={props.modalContentDisclosure.isOpen}
                  onClose={() => {
                    setIsEditName(false);
                    setCurrentName(props.instance.metadata.name);
                    props.nextmodalContentDisclosure.onClose();
                    props.modalContentDisclosure.onClose();
                  }}
                  autoFocus={false}
                  isCentered
                  scrollBehavior="inside"
                  size="xl"
                >
                  <ModalOverlay />
                  <form onSubmit={props.formRef.handleSubmit(onSubmit)}>
                    <ModalContent padding="16px 8px" maxHeight="90%">
                      <ModalHeader>
                        <HStack spacing={5}>
                          <Flex
                            width="64px"
                            height="64px"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="8px"
                            backgroundColor={hexToRgbA(
                              processorDefinition.color,
                              true
                            )}
                          >
                            <Icon
                              fontSize={30}
                              as={ProcessorIcon}
                              style={{ color: processorDefinition.color }}
                            />
                          </Flex>
                          <VStack alignItems="normal" spacing={1.5}>
                            <HStack>
                              {isEditName ? (
                                <FormControl>
                                  <Input
                                    backgroundColor="white"
                                    variant="flushed"
                                    size="xl"
                                    fontWeight="bold"
                                    value={currentName}
                                    onChange={handleRename}
                                  />
                                </FormControl>
                              ) : (
                                <Text fontSize="20px">
                                  {props.instance.metadata.name}
                                </Text>
                              )}

                              <Icon
                                as={isEditName ? Bi.BiUndo : Bi.BiEditAlt}
                                onClick={handleToggleRename}
                                cursor="pointer"
                              />
                            </HStack>
                            <Text
                              fontSize="14px"
                              fontWeight={400}
                              color="#6F809A"
                            >
                              {processorDefinition.copywrite}
                            </Text>
                          </VStack>
                          <Spacer></Spacer>
                          <Flex alignSelf="start">
                            <Icon
                              as={Bi.BiX}
                              onClick={() => {
                                setIsEditName(false);
                                setCurrentName(props.instance.metadata.name);
                                props.modalContentDisclosure?.onClose();
                              }}
                              cursor="pointer"
                            />
                          </Flex>
                        </HStack>
                      </ModalHeader>
                      <Divider></Divider>
                      <ModalBody py="24px">
                        <VStack alignItems="normal" fontSize="14px" spacing={3}>
                          <HStack>
                            <Icon fontSize="large" as={Bi.BiLogInCircle} />
                            <Text>Process</Text>
                          </HStack>
                          <Box
                            width="100%"
                            style={
                              props.instance.metadata.name !== "File Processor"
                                ? settingModalStyle
                                : {}
                            }
                          >
                            {props.settingModal}
                          </Box>
                          {/* <Icon
                            as={IconArrowDown}
                            fontSize="x-large"
                            alignSelf="center"
                            color="#6F809A"
                          />
                          <VStack alignItems="normal" spacing={1}>
                            <HStack>
                              <Icon fontSize="large" as={Bi.BiLogInCircle} />
                              <Text>Output</Text>
                            </HStack>
                            <Text color="#6F809A">
                              Set what data will be sent to next process
                            </Text>
                          </VStack>
                          <VStack
                            alignItems="normal"
                            backgroundColor="#F9F9FA"
                            border="1px solid #E6E9EC"
                            padding="16px"
                            spacing={4}
                            borderRadius="4px"
                          >
                            <Text color="#6F809A">
                              This process will send all fields from All Sales.
                            </Text>
                            <HStack color="#0065FF">
                              <Icon fontSize="large" as={Bi.BiSearchAlt2} />
                              <Text>See sample output</Text>
                            </HStack>
                          </VStack> */}
                        </VStack>
                      </ModalBody>
                      <Divider></Divider>
                      <VStack
                        alignItems="normal"
                        spacing={6}
                        margin="0px -8px -16px -8px"
                        padding="16px 32px 32px 32px"
                        backgroundColor="#F9F9FA"
                      >
                        {/* <HStack color="#6F809A">
                          <Icon as={Bi.BiBulb} />
                          <Text fontSize="14px">
                            Visit our learning resource to learn more about{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {processorDefinition.id}.
                            </span>
                          </Text>
                        </HStack> */}
                        <Flex>
                          <Box ref={props.portalRef}></Box>
                          <Spacer></Spacer>
                          <ButtonGroup spacing="3">
                            <Button
                              variant="outline"
                              width="112px"
                              onClick={() => {
                                setIsEditName(false);
                                setCurrentName(props.instance.metadata.name);
                                props.modalContentDisclosure?.onClose();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="solid"
                              width="112px"
                              colorScheme="blue"
                              type="submit"
                              onClick={() => {
                                setIsEditName(false);
                              }}
                              disabled={disableSave}
                            >
                              Save
                            </Button>
                          </ButtonGroup>
                        </Flex>
                      </VStack>
                    </ModalContent>
                  </form>
                </Modal>
                <Icon
                  cursor="pointer"
                  fontSize={20}
                  as={Bi.BiTrash}
                  onClick={handleRemoveProcessor}
                />
              </HStack>
            </Box>
            {processorDefinition.type === "source" && (
              <Box
                width="auto"
                backgroundColor="#EBF5E9"
                padding="2px 8px"
                borderRadius="4px"
                color={processorDefinition.color}
                fontSize="12px"
                fontWeight="600"
              >
                Trigger
              </Box>
            )}
          </HStack>
          <Box paddingX="16px">
            <Flex className="drag-handle" height="52px" alignItems="center">
              <Box flex={1}>
                <Text
                  css={css`
                    text-overflow: ellipsis;
                    word-break: break-word;
                    height: 24px;
                    width: 272px;
                    overflow: hidden;
                    white-space: nowrap;
                  `}
                >
                  {props.instance.metadata.name}
                </Text>
              </Box>
              <Box cursor="pointer" onClick={collapseDisclosure.onToggle}>
                <Icon
                  as={Bi.BiChevronDown}
                  fontSize="18px"
                  style={collapseStyle}
                />
              </Box>
            </Flex>
            <Collapse in={collapseDisclosure.isOpen} animateOpacity>
              <Divider />
              <Box bg="white" paddingY="14px">
                {props.expandedContent}
              </Box>
            </Collapse>
          </Box>
        </Box>
      </Tooltip>
      <HStack
        justifyContent="space-around"
        position="relative"
        bottom="-8"
        width="320px"
        height="34px"
      >
        {processorDefinition.outputs.map((output, i) => {
          return (
            <VStack height="100px" align="center" key={output}>
              <svg
                style={{
                  position: "absolute",
                  bottom: -5,
                  zIndex: -1,
                }}
                version="1.1"
                id="line_2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60px"
                height="70px"
              >
                <path
                  d="M30 0 v100 300"
                  stroke={handleSelectedEdgeStroke(output)}
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              <Box
                style={{
                  borderRadius: "4px",
                  border: "none",
                  width: "auto",
                  height: "auto",
                  padding: "2px 10px",
                  background: `${
                    i === 1 ? "rgb(252, 233, 231)" : "rgb(230, 240, 255)"
                  }`,
                  filter: "drop-shadow( 0px 2px 8px rgba(0, 30, 77, 0.08))",
                  fontSize: "13px",
                  fontWeight: 600,
                  color: `${i === 1 ? "#AA1A08" : "#004DC4"}`,
                }}
              >
                <Box
                  cursor="pointer"
                  onClick={() => {
                    const totalOutputs = stats.data?.outputs?.[output] || 0;
                    setSelectedPage(1);
                    setSelectedBucket(output);
                    setProcessorOutputPages(
                      _.ceil(totalOutputs / pagination, 0)
                    );
                    props.modalBucketContentDisclosure.onOpen();
                  }}
                >
                  {output} [{stats.data?.outputs?.[output] || 0}]
                </Box>
                <Modal
                  isOpen={props.modalBucketContentDisclosure.isOpen}
                  onClose={() => {
                    props.modalBucketContentDisclosure.onClose();
                    setSelectedPage(0);
                  }}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>List of Output ID</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <VStack>
                        <Accordion
                          allowMultiple
                          allowToggle
                          width="95%"
                          height="100%"
                        >
                          {processorOutputData.map((output: any) => {
                            return (
                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box
                                      flex="1"
                                      textAlign="left"
                                      fontSize="15px"
                                    >
                                      {output.id}
                                    </Box>
                                    <Button
                                      variant="solid"
                                      size="sm"
                                      colorScheme="blue"
                                      marginRight="10px"
                                      onClick={() => {
                                        handleClickTrace(output.id);
                                      }}
                                    >
                                      Trace
                                    </Button>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                  <Editor
                                    width="100%"
                                    height="200px"
                                    language="json"
                                    value={JSON.stringify(output.file, null, 2)}
                                    options={{
                                      minimap: { enabled: false },
                                      readOnly: true,
                                      lineNumbers: "off",
                                    }}
                                  />
                                </AccordionPanel>
                              </AccordionItem>
                            );
                          })}
                        </Accordion>
                        {(stats.data?.outputs?.[selectedBucket] || 0) !== 0 ? (
                          <HStack>
                            <Icon
                              cursor="pointer"
                              fontSize={20}
                              as={Bi.BiChevronsLeft}
                              onClick={() => setSelectedPage(1)}
                            />
                            <Icon
                              cursor="pointer"
                              fontSize={20}
                              as={Bi.BiChevronLeft}
                              onClick={handleClickPrevious}
                            />
                            {selectedPage - boundPagination > 1 && (
                              <Box>...</Box>
                            )}
                            {generateNumPages().map((num) => {
                              return (
                                <Box
                                  textColor={
                                    num == selectedPage ? "blue" : "black"
                                  }
                                  onClick={() => setSelectedPage(num)}
                                  cursor="pointer"
                                >
                                  {num}
                                </Box>
                              );
                            })}
                            {selectedPage + boundPagination <
                              processorOutputPages && <Box>...</Box>}
                            {(stats.data?.outputs?.[output] || 0) > 5 && (
                              <>
                                <Icon
                                  cursor="pointer"
                                  fontSize={20}
                                  as={Bi.BiChevronRight}
                                  onClick={handleClickNext}
                                />
                                <Icon
                                  cursor="pointer"
                                  fontSize={20}
                                  as={Bi.BiChevronsRight}
                                  onClick={() =>
                                    setSelectedPage(processorOutputPages)
                                  }
                                />
                              </>
                            )}
                          </HStack>
                        ) : (
                          <Box>No Output.</Box>
                        )}
                      </VStack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={() => {
                          props.modalBucketContentDisclosure.onClose();
                        }}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
                <Handle
                  isConnectable={false}
                  id={output}
                  className={disableHandlerClass}
                  position={Position.Bottom}
                  type="source"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    border: "none",
                    borderRadius: 0,
                  }}
                />
              </Box>
              <AddProcessorMenu
                position="bottom"
                output={output}
                instanceID={props.instance.id}
              />
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
}
