import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { UseFormReturn, NestedValue } from "react-hook-form";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { ProcessorInstance } from "../../ProcessorInstance";
import { FrequencyVariant } from "./definition";
import DatePicker from "react-datepicker";

type OptionsInstance = {
  frequency: FrequencyVariant;
  isRepeat: boolean;
  startDate: string;
  endDate: string;
  script: string;
  tz: string;
};

const days = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

const dates = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

const customIntervalTimes = [
  { name: "minutes", template: "*/c h * * *", count: 0 },
  { name: "hours", template: "m */c * * *", count: 0 },
  { name: "weeks", template: "m h * * D", count: 7 },
  { name: "days", template: "m h */1 * *", count: 1 },
  { name: "months", template: "m h d * *", count: 28 },
];

export function Hourly(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Hourly" }>;
}) {
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const startTimeDisclosure = useDisclosure();
  const endTimeDisclosure = useDisclosure();
  const disabledButton = React.useMemo(() => {
    return !props.data.isRange;
  }, [props.data.isRange]);
  const setTime = React.useCallback(
    (date: Date, type: string) => {
      if (type === "start") {
        props.formRef.setValue(
          "options.startDate",
          new Date(
            startDate.setHours(date.getHours(), date.getMinutes())
          ).toISOString()
        );
      }
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, startDate, endDate]
  );

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <Switch
          id="is-range"
          isChecked={props.data.isRange}
          {...props.formRef.register("options.frequency.isRange", {
            onChange: (e) => {
              if (!e.target.checked) {
                props.formRef.setValue(
                  "options.frequency.template",
                  "m */1 * * *"
                );
                props.formRef.setValue(
                  "options.startDate",
                  startDate.toDateString() !== new Date().toDateString()
                    ? new Date(startDate.setHours(0, 0)).toISOString()
                    : new Date().toISOString()
                );
                props.formRef.setValue(
                  "options.endDate",
                  new Date(endDate.setHours(23, 59)).toISOString()
                );
              }
            },
          })}
        />
        <FormLabel htmlFor="is-range" ml="16px" mb="0">
          Only run at certain time range each day
        </FormLabel>
      </FormControl>
      <HStack width="100%">
        <FormControl width="49%">
          <FormLabel>Start time for each day</FormLabel>
          <Menu
            onClose={startTimeDisclosure.onClose}
            onOpen={startTimeDisclosure.onOpen}
            isOpen={startTimeDisclosure.isOpen}
          >
            <MenuButton
              disabled={disabledButton}
              as={Button}
              width="100%"
              fontWeight="400"
              size="sm"
              textAlign="start"
              rightIcon={
                startTimeDisclosure.isOpen ? <BiChevronUp /> : <BiChevronDown />
              }
            >
              {startDate.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
              })}
            </MenuButton>
            <MenuList
              paddingTop="0px"
              paddingBottom="0px"
              height="250px"
              minWidth="0"
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setTime(date, "start");
                  startTimeDisclosure.onClose();
                  endTimeDisclosure.onOpen();
                }}
                maxTime={new Date(new Date().setHours(23, 59))}
                minTime={new Date(new Date().setHours(0, 0))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                inline
              />
            </MenuList>
          </Menu>
        </FormControl>
        <Text alignSelf="flex-end" pb="2" width="2%">
          -
        </Text>
        <FormControl width="49%">
          <FormLabel>End time for each day</FormLabel>
          <Menu
            onClose={endTimeDisclosure.onClose}
            onOpen={endTimeDisclosure.onOpen}
            isOpen={endTimeDisclosure.isOpen}
          >
            <MenuButton
              disabled={disabledButton}
              width="100%"
              fontWeight="400"
              size="sm"
              textAlign="start"
              as={Button}
              rightIcon={
                endTimeDisclosure.isOpen ? <BiChevronUp /> : <BiChevronDown />
              }
            >
              {endDate.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
              })}
            </MenuButton>
            <MenuList
              paddingTop="0px"
              paddingBottom="0px"
              height="250px"
              minWidth="0"
            >
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => {
                  setTime(date, "end");
                  endTimeDisclosure.onClose();
                }}
                minTime={new Date(new Date().setHours(0, 0))}
                maxTime={new Date(new Date().setHours(23, 59))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                inline
              />
            </MenuList>
          </Menu>
        </FormControl>
      </HStack>
    </>
  );
}

export function Daily(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Daily" }>;
}) {
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const setTime = React.useCallback(
    (date: Date) => {
      props.formRef.setValue(
        "options.startDate",
        new Date(
          startDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, props.data]
  );
  return (
    <FormControl>
      <FormLabel>Time</FormLabel>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              isActive={isOpen}
              width="100%"
              fontWeight="400"
              size="sm"
              textAlign="start"
              as={Button}
              rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
            >
              {startDate.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
              })}
            </MenuButton>
            <MenuList
              paddingTop="0px"
              paddingBottom="0px"
              height="250px"
              minWidth="0"
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setTime(date);
                  onClose();
                }}
                minTime={new Date(new Date().setHours(0, 0))}
                maxTime={new Date(new Date().setHours(23, 59))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                inline
              />
            </MenuList>
          </>
        )}
      </Menu>
    </FormControl>
  );
}

export function Workdays(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Workdays (Mon-Fri)" }>;
}) {
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const setTime = React.useCallback(
    (date: Date) => {
      props.formRef.setValue(
        "options.startDate",
        new Date(
          startDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, props.data]
  );
  return (
    <FormControl>
      <FormLabel>Time</FormLabel>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              isActive={isOpen}
              width="100%"
              fontWeight="400"
              size="sm"
              textAlign="start"
              as={Button}
              rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
            >
              {startDate.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
              })}
            </MenuButton>
            <MenuList
              paddingTop="0px"
              paddingBottom="0px"
              height="250px"
              minWidth="0"
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setTime(date);
                  onClose();
                }}
                minTime={new Date(new Date().setHours(0, 0))}
                maxTime={new Date(new Date().setHours(23, 59))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                inline
              />
            </MenuList>
          </>
        )}
      </Menu>
    </FormControl>
  );
}

export function Weekly(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Weekly" }>;
}) {
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const setTime = React.useCallback(
    (date: Date) => {
      props.formRef.setValue(
        "options.startDate",
        new Date(
          startDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, props.data]
  );
  return (
    <HStack width="100%">
      <FormControl width="50%">
        <FormLabel>Day</FormLabel>
        <Select
          {...props.formRef.register("options.frequency.day", {
            onChange: (e) => {
              let script = "m h * * D";
              script = script.replace("D", `${e.currentTarget.value}`);
              props.formRef.setValue("options.frequency.template", script);
            },
          })}
        >
          {days.map((day, i: React.Key | null | undefined) => (
            <option value={day.id} key={i}>
              {day.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl width="50%">
        <FormLabel>Time</FormLabel>
        <Menu>
          {({ isOpen, onClose }) => (
            <>
              <MenuButton
                isActive={isOpen}
                textAlign="start"
                size="sm"
                fontWeight="400"
                width="100%"
                as={Button}
                rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
              >
                {startDate.toLocaleTimeString("en-us", {
                  hour: "numeric",
                  minute: "numeric",
                })}
              </MenuButton>
              <MenuList
                paddingTop="0px"
                paddingBottom="0px"
                height="250px"
                minWidth="0"
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => {
                    setTime(date);
                    onClose();
                  }}
                  minTime={new Date(new Date().setHours(0, 0))}
                  maxTime={new Date(new Date().setHours(23, 59))}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  inline
                />
              </MenuList>
            </>
          )}
        </Menu>
      </FormControl>
    </HStack>
  );
}

export function Monthly(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Monthly" }>;
}) {
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const setTime = React.useCallback(
    (date: Date) => {
      props.formRef.setValue(
        "options.startDate",
        new Date(
          startDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, props.data]
  );
  return (
    <HStack width="100%">
      <FormControl width="50%">
        <FormLabel>Day</FormLabel>
        <Select
          {...props.formRef.register("options.frequency.date", {
            onChange: (e) => {
              let script = "m h d * *";
              script = script.replace("d", `${e.currentTarget.value}`);
              props.formRef.setValue("options.frequency.template", script);
            },
          })}
        >
          {dates.map((date) => (
            <option value={date} key={date}>
              {date}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl width="50%">
        <FormLabel>Time</FormLabel>
        <Menu>
          {({ isOpen, onClose }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                fontWeight="400"
                size="sm"
                textAlign="start"
                width="100%"
                rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
              >
                {startDate.toLocaleTimeString("en-us", {
                  hour: "numeric",
                  minute: "numeric",
                })}
              </MenuButton>
              <MenuList
                paddingTop="0px"
                paddingBottom="0px"
                height="250px"
                minWidth="0"
              >
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date) => {
                    setTime(date);
                    onClose();
                  }}
                  minTime={new Date(new Date().setHours(0, 0))}
                  maxTime={new Date(new Date().setHours(23, 59))}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  inline
                />
              </MenuList>
            </>
          )}
        </Menu>
      </FormControl>
    </HStack>
  );
}

export function Custom(props: {
  formRef: UseFormReturn<{
    options: NestedValue<OptionsInstance>;
    metadata: NestedValue<ProcessorInstance["metadata"]>;
  }>;
  data: Extract<FrequencyVariant, { name: "Custom interval..." }>;
}) {
  const disabledButton = React.useMemo(() => {
    return !props.data.isRange;
  }, [props.data.isRange]);
  const startTimeDisclosure = useDisclosure();
  const endTimeDisclosure = useDisclosure();
  const instanceData = props.formRef.watch();
  const startDate = new Date(instanceData.options.startDate);
  const endDate = new Date(instanceData.options.endDate);
  const setTime = React.useCallback(
    (date: Date, type: string) => {
      if (type === "start") {
        props.formRef.setValue(
          "options.startDate",
          new Date(
            startDate.setHours(date.getHours(), date.getMinutes())
          ).toISOString()
        );
      }
      props.formRef.setValue(
        "options.endDate",
        new Date(
          endDate.setHours(date.getHours(), date.getMinutes())
        ).toISOString()
      );
    },
    [props.formRef, startDate, endDate]
  );
  const frequencySelects = React.useMemo(() => {
    const oneDay = 24 * 60 * 60 * 1000;
    const start = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const end = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const diffDays = Math.round(Math.abs((start - end) / oneDay));
    return customIntervalTimes.filter(
      (item: { count: number }) => diffDays >= item.count
    );
  }, [startDate, endDate]);
  return (
    <>
      <HStack width="100%">
        <Text fontWeight="600" width="50%">
          Start Workflow Every
        </Text>
        <FormControl paddingBottom="6px" width="40%">
          <Input
            mt="8px"
            type="number"
            defaultValue={props.data.value}
            placeholder="1"
            required
            {...props.formRef.register("options.frequency.value", {
              onChange: (e) => {
                const selectedFrequency = frequencySelects.find(
                  (item) => item.name === props.data.type
                )!;
                let value = 1;
                if (e.currentTarget.value) {
                  value = e.currentTarget.value;
                }
                props.formRef.setValue(
                  "options.frequency.template",
                  selectedFrequency.template.replace("c", `${value}`)
                );
              },
            })}
          />
        </FormControl>
        <Select
          variant="unstyled"
          width="40%"
          value={props.data.type}
          {...props.formRef.register("options.frequency.type", {
            onChange: (e) => {
              const selectedFrequency = frequencySelects.find(
                (item) => item.name === e.currentTarget.value
              )!;
              let value = 1;
              if (props.data.value) {
                value = props.data.value;
              }
              props.formRef.setValue(
                "options.frequency.template",
                selectedFrequency.template.replace("c", `${value}`)
              );
            },
          })}
        >
          {frequencySelects.map((item, i) => (
            <option value={item.name} key={i}>
              {item.name}
            </option>
          ))}
        </Select>
      </HStack>
      {props.data.type === "hours" || props.data.type === "minutes" ? (
        <>
          <FormControl display="flex" alignItems="center">
            <Switch
              id="is-custom-range"
              {...props.formRef.register("options.frequency.isRange", {
                onChange: (e) => {
                  const selectedInterval = customIntervalTimes.find(
                    (el) => el.name === props.data.type
                  )!;
                  let script = selectedInterval.template;
                  if (!e.target.checked) {
                    props.formRef.setValue(
                      "options.frequency.template",
                      selectedInterval.name === "hours"
                        ? script.replace("h", "*")
                        : script.replace("m", "*")
                    );
                    props.formRef.setValue(
                      "options.startDate",
                      startDate.toDateString() !== new Date().toDateString()
                        ? new Date(startDate.setHours(0, 0)).toISOString()
                        : new Date().toISOString()
                    );
                    props.formRef.setValue(
                      "options.endDate",
                      new Date(endDate.setHours(23, 59)).toISOString()
                    );
                  }
                },
              })}
            />
            <FormLabel htmlFor="is-custom-range" ml="16px" mb="0">
              Only run at certain time range each day
            </FormLabel>
          </FormControl>
          <HStack width="100%">
            <FormControl width="50%">
              <FormLabel>Start time for each day</FormLabel>
              <Menu
                onClose={startTimeDisclosure.onClose}
                onOpen={startTimeDisclosure.onOpen}
                isOpen={startTimeDisclosure.isOpen}
              >
                <MenuButton
                  disabled={disabledButton}
                  as={Button}
                  size="sm"
                  fontWeight="400"
                  textAlign="start"
                  width="100%"
                  rightIcon={
                    startTimeDisclosure.isOpen ? (
                      <BiChevronUp />
                    ) : (
                      <BiChevronDown />
                    )
                  }
                >
                  {startDate.toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </MenuButton>
                <MenuList
                  paddingTop="0px"
                  paddingBottom="0px"
                  height="250px"
                  minWidth="0"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                      setTime(date, "start");
                      startTimeDisclosure.onClose();
                      endTimeDisclosure.onOpen();
                    }}
                    maxTime={new Date(new Date().setHours(23, 59))}
                    minTime={new Date(new Date().setHours(0, 0))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    inline
                  />
                </MenuList>
              </Menu>
            </FormControl>
            <Text alignSelf="flex-end" pb="2">
              -
            </Text>
            <FormControl width="50%">
              <FormLabel>End time for each day</FormLabel>
              <Menu
                onClose={endTimeDisclosure.onClose}
                onOpen={endTimeDisclosure.onOpen}
                isOpen={endTimeDisclosure.isOpen}
              >
                <MenuButton
                  disabled={disabledButton}
                  size="sm"
                  fontWeight="400"
                  as={Button}
                  textAlign="start"
                  width="100%"
                  rightIcon={
                    endTimeDisclosure.isOpen ? (
                      <BiChevronUp />
                    ) : (
                      <BiChevronDown />
                    )
                  }
                >
                  {endDate.toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </MenuButton>
                <MenuList
                  paddingTop="0px"
                  paddingBottom="0px"
                  height="250px"
                  minWidth="0"
                >
                  <DatePicker
                    selected={endDate}
                    onChange={(date: Date) => {
                      setTime(date, "end");
                      endTimeDisclosure.onClose();
                    }}
                    minTime={new Date(new Date().setHours(0, 0))}
                    maxTime={new Date(new Date().setHours(23, 59))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    inline
                  />
                </MenuList>
              </Menu>
            </FormControl>
          </HStack>
        </>
      ) : props.data.type === "weeks" ? (
      <HStack width="100%">
        <FormControl width="50%">
            <FormLabel>Day</FormLabel>
            <Select
              {...props.formRef.register("options.frequency.day", {
                onChange: (e) => {
                  let script = "m h * * D";
                  script = script.replace("D", `${e.currentTarget.value}`);
                  props.formRef.setValue("options.frequency.template", script);
                },
              })}
            >
              {days.map((day, i: React.Key | null | undefined) => (
                <option value={day.id} key={i}>
                  {day.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Time</FormLabel>
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    size="sm"
                    textAlign="start"
                    width="100%"
                    rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
                  >
                    {startDate.toLocaleTimeString("en-us", {
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </MenuButton>
                  <MenuList
                    paddingTop="0px"
                    paddingBottom="0px"
                    height="250px"
                    minWidth="0"
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => {
                        setTime(date, "start");
                        onClose();
                      }}
                      minTime={new Date(new Date().setHours(0, 0))}
                      maxTime={new Date(new Date().setHours(23, 59))}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      inline
                    />
                  </MenuList>
                </>
              )}
            </Menu>
          </FormControl>
        </HStack>
      ) : props.data.type === "months" ? (
      <HStack width="100%">
        <FormControl width="50%">
          <FormLabel>Day</FormLabel>
          <Select
            {...props.formRef.register("options.frequency.date", {
              onChange: (e) => {
                let script = "m h d * *";
                script = script.replace("d", `${e.currentTarget.value}`);
                props.formRef.setValue("options.frequency.template", script);
              },
            })}
          >
            {dates.map((date) => (
              <option value={date} key={date}>
                {date}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Time</FormLabel>
          <Menu>
            {({ isOpen, onClose }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  size="sm"
                  textAlign="start"
                  width="100%"
                  rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
                >
                  {startDate.toLocaleTimeString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </MenuButton>
                <MenuList
                  paddingTop="0px"
                  paddingBottom="0px"
                  height="250px"
                  minWidth="0"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date: Date) => {
                      setTime(date, "start");
                      onClose();
                    }}
                    minTime={new Date(new Date().setHours(0, 0))}
                    maxTime={new Date(new Date().setHours(23, 59))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={60}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    inline
                  />
                </MenuList>
              </>
            )}
          </Menu>
        </FormControl>
      </HStack>
      ) : 
      <FormControl>
      <FormLabel>Time</FormLabel>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              isActive={isOpen}
              as={Button}
              size="sm"
              textAlign="start"
              width="100%"
              rightIcon={isOpen ? <BiChevronUp /> : <BiChevronDown />}
            >
              {startDate.toLocaleTimeString("en-us", {
                hour: "numeric",
                minute: "numeric",
              })}
            </MenuButton>
            <MenuList
              paddingTop="0px"
              paddingBottom="0px"
              height="250px"
              minWidth="0"
            >
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => {
                  setTime(date, "start");
                  onClose();
                }}
                minTime={new Date(new Date().setHours(0, 0))}
                maxTime={new Date(new Date().setHours(23, 59))}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="h:mm aa"
                inline
              />
            </MenuList>
          </>
        )}
      </Menu>
    </FormControl>
    }
    </>
  );
}
