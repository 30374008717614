import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "logic and flow",
  { function: string, isValid: boolean },
  "Executed" | "Error"
>({
  id: "JavaScript",
  type: QonduitResourceTypes.Operator,
  category: "logic and flow",
  color: "#4D93FF",
  copywrite: "Custom javascript code",
  icon: "BiCodeCurly",
  options: {
    function: `
function myFunction(input) {
  return input
}
  `,
    isValid: true
  },
  schema: {
    type: "object",
    required: ["function"],
    properties: { function: { type: "string" }, isValid: { type: "boolean" } },
  },
  outputs: ["Executed", "Error"],
});
