import {
  Button,
  Box,
  useToast,
  HStack,
  VStack,
  FormControl,
  FormLabel,
  Switch,
  Flex,
  Text,
  Collapse,
  Portal,
  useDisclosure,
  ButtonGroup,
  SlideFade,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import copy from "copy-to-clipboard";
import Cookies from "js-cookie";
import { BiCopy, BiPlay, BiRocket } from "react-icons/bi";
import { registerNode } from "../../ReactNodes";
import { useWorkflowBuilder } from "../../WorkflowBuilderProvider";
import Editor from "@monaco-editor/react";
import { useDebounce } from "react-use";
import definition from "./definition";
import { useClient } from "../../../utils/QonduitClient";
import { JSONSchema7Object } from "json-schema";
import { useWatch } from "react-hook-form";
import QonduitContext from "../../../components/QonduitContext";
import useSWR from "swr";
import env from "../../../utils/env";
import axios from "axios";

registerNode(
  definition,
  (props) => {
    const client = useClient();
    const {
      state: { id: workflowID },
    } = useWorkflowBuilder();
    const toast = useToast();
    const data = props.formRef.watch();
    // @ts-ignore
    const isEnableWebhook = props.formRef.watch("options.enableWebhook");
    const [disableSave, setDisableSave] = React.useState<boolean>(false);
    const [state, setState] = React.useState<{
      synchronous: boolean;
      loading: boolean;
      response?: JSONSchema7Object;
    }>({
      synchronous: false,
      loading: false,
    });
    const handlePayloadChange = React.useCallback(
      (val?: string) => {
        if (!val) {
          setDisableSave(true);
          return;
        }
        setDisableSave(false);
        const payload = JSON.parse(val);
        // @ts-ignore
        props.formRef.setValue("options.payload", payload);
      },
      [setState]
    );

    const handleSaveconfiguration = React.useCallback(() => {
      props.modifyOptions((draft) => {
        const keys = Object.keys(data.options);
        // @ts-ignore
        keys.forEach((key: string) => (draft[key] = data.options[key]));
      });
      toast({
        description: "Your code has been saved successfully",
        isClosable: true,
        status: "success",
      });
    }, [props.formRef, data]);

    const handleCopyAsCURL = React.useCallback(() => {
      const isPublic = props.formRef.watch("options.isPublic");
      const adminSecret = `-H  "x-qonduit-admin-secret: ${Cookies.get(
        "qonduit-admin-secret"
      )}"`;
      const text = `curl -X POST "${
        env.NEXT_PUBLIC_API_ENDPOINT
      }/api/workflow/${workflowID}/instance/${
        props.instance.id
      }/inbound" -H "accept: */*" ${
        !isPublic ? adminSecret : ""
      } -H "Content-Type: application/json" -d "{\\"test\\":4}"`;
      copy(text);
      toast({
        title: "Copied as cURL",
        description: "Use this command to send data to this webhook",
        isClosable: true,
        status: "success",
      });
    }, [workflowID, props.instance.id]);

    const handleTrigger = React.useCallback(async () => {
      try {
        setState((state) => ({ ...state, loading: true }));
        const payload = props.formRef.watch("options.payload");
        const response = state.synchronous
          ? await client.execute(
              props.instance.id,
              workflowID,
              payload === undefined ? props.instance.options.payload : payload
            )
          : await client.sendData(
              props.instance.id,
              workflowID,
              payload === undefined ? props.instance.options.payload : payload
            );
        if (!response)
          throw new Error("An Error occured while running workflow");
        setState((state) => ({
          ...state,
          loading: false,
          response: response.data as unknown as JSONSchema7Object,
        }));

        const resp = await axios.get(
          `${env.NEXT_PUBLIC_API_ENDPOINT}/api/workflows/${workflowID}/status`,
          {
            headers: {
              "x-qonduit-admin-secret": Cookies.get("qonduit-access-token"),
            },
          }
        );

        if (resp.data.isActive) {
          toast({
            description: "Your workflow has run successfully",
            isClosable: true,
            status: "success",
          });
        } else {
          toast({
            description:
              "Pipeline is inactive, please activated the pipeline first",
            isClosable: true,
            status: "warning",
          });
        }
      } catch (error) {
        toast({
          description: "An Error occured while running workflow",
          isClosable: true,
          status: "error",
        });
      }
    }, [state.synchronous, props.instance.options.payload, props.formRef]);
    return (
      <>
        <VStack alignItems="normal" spacing="5">
          <HStack spacing="5">
            <Switch
              size="sm"
              {...props.formRef.register("options.enableWebhook")}
            />
            <VStack alignItems="normal" spacing="1">
              <Text fontSize="14px" fontWeight="600" color="#001E4D">
                Enable webhook
              </Text>
              <Text fontSize="14px" fontWeight="400" color="#6F809A">
                When enabled, this workflow can be executed from URL
              </Text>
            </VStack>
          </HStack>
          <Collapse in={data.options.enableWebhook} animateOpacity>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingX="16px"
            >
              <Button
                size="sm"
                onClick={handleCopyAsCURL}
                leftIcon={<BiCopy fontSize="17px" />}
              >
                Copy as cURL
              </Button>
              <HStack>
                <Switch
                  size="sm"
                  {...props.formRef.register("options.isPublic")}
                />
                <Text fontSize="14px" fontWeight="600" color="#001E4D">
                  Integration Qore App
                </Text>
              </HStack>
            </Flex>
          </Collapse>
          <Portal containerRef={props.portalRef}>
            <Button
              variant="solid"
              leftIcon={<BiRocket />}
              onClick={props.nextmodalContentDisclosure?.onOpen}
              colorScheme="blue"
            >
              Manual Trigger
            </Button>
          </Portal>
        </VStack>
        <Modal
          autoFocus={false}
          isCentered
          scrollBehavior="inside"
          size="5xl"
          isOpen={props.nextmodalContentDisclosure?.isOpen!}
          onClose={() => {
            props.nextmodalContentDisclosure?.onClose()!;
            props.modalContentDisclosure?.onClose();
          }}
        >
          <ModalContent isLazy padding="16px 8px" maxHeight="90%">
            <ModalHeader>{props.instance.metadata.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack alignItems="normal" spacing="5">
                <HStack alignItems="normal" spacing="5" width="100%">
                  <VStack justifyContent="space-between" width="50%">
                    <FormControl isRequired={true}>
                      <FormLabel>Payload</FormLabel>
                      <Editor
                        height="50vh"
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(
                          props.instance.options.payload,
                          null,
                          2
                        )}
                        onChange={handlePayloadChange}
                        options={{ minimap: { enabled: false } }}
                      />
                    </FormControl>
                    <HStack spacing="5">
                      <Switch
                        size="sm"
                        defaultChecked={state.synchronous}
                        onChange={(e) => {
                          setState((state) => ({
                            ...state,
                            synchronous: e.currentTarget.checked,
                          }));
                        }}
                        id="enable-sync"
                      />
                      <VStack alignItems="normal" spacing="1">
                        <Text fontSize="14px" fontWeight="600" color="#001E4D">
                          Synchronous mode
                        </Text>
                        <Text fontSize="14px" fontWeight="400" color="#6F809A">
                          Wait until final result comes (instead of running in
                          background) when triggered manually
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>
                  <FormControl width="50%">
                    <FormLabel>Result</FormLabel>
                    {!state.loading && state.response ? (
                      <Editor
                        height="60vh"
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(state.response, null, 2)}
                        options={{ minimap: { enabled: false } }}
                      />
                    ) : (
                      <Textarea
                        isDisabled={true}
                        style={{ resize: "none" }}
                        height="60vh"
                        backgroundColor="#F9F9FA"
                        value="Not yet triggered"
                      />
                    )}
                  </FormControl>
                </HStack>
                <Flex justifyContent="space-between">
                  <Button
                    width="112px"
                    onClick={props.nextmodalContentDisclosure?.onToggle}
                  >
                    Cancel
                  </Button>
                  <ButtonGroup spacing="3">
                    <Button
                      variant="ghost"
                      onClick={handleSaveconfiguration}
                      type="submit"
                      disabled={disableSave}
                    >
                      Save configuration
                    </Button>
                    <Button
                      leftIcon={<BiRocket />}
                      colorScheme="blue"
                      variant="solid"
                      disabled={state.loading}
                      isLoading={state.loading}
                      onClick={handleTrigger}
                    >
                      Trigger now
                    </Button>
                  </ButtonGroup>
                </Flex>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            Webhook
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.enableWebhook ? "Enabled" : "Disabled"}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
