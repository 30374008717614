import { JSONSchema7Object } from "json-schema";
import {
  QonduitProcessorDefinition,
  QonduitResourceTypes,
  QonduitResourceCategories,
} from "./interfaces";

const definitions: Record<string, QonduitProcessorDefinition> = {};

export function buildProcessorDefinition<
  TResourceType extends QonduitResourceTypes = QonduitResourceTypes,
  TResourceCategory extends QonduitResourceCategories = QonduitResourceCategories,
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object,
  TOutputs extends string = string
>(
  definition: QonduitProcessorDefinition<
    TResourceType,
    TResourceCategory,
    TSchemaObject,
    TOutputs
  >
) {
  // @ts-ignore
  definitions[definition.id] = definition;
  return definition;
}

export function getProcessorDefinition(id: string) {
  return definitions[id];
}

export const getDefinitions = () => definitions;
