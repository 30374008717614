import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Text } from "@chakra-ui/layout";
import { VStack, ButtonGroup, Button, useToast } from "@chakra-ui/react";
import React from "react";
import QonduitContext from "../../../components/QonduitContext";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";

registerNode(
  definition,
  (props) => {
    return (
      <VStack alignItems="flex-end" spacing="5">
        <FormControl paddingBottom="6px">
          <FormLabel>Path</FormLabel>
          <Input
            size="sm"
            backgroundColor="white"
            placeholder="path.to.array"
            {...props.formRef.register("options.path")}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return (
      <VStack alignItems="normal">
        <Flex justifyContent="space-between">
          <Text fontWeight="600" fontSize="12px" color="#001E4D">
            JSON path
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {props.instance.options.path}
          </Text>
        </Flex>
      </VStack>
    );
  }
);
