import {
  FormControl,
  VStack,
  Button,
  FormLabel,
  Box,
  Flex,
  Progress,
  Badge,
  Text,
  HStack,
  IconButton,
  Center,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { registerNode } from "../../ReactNodes";
import definition, { FileUploadMetadata } from "./definition";
import axios from "axios";
import env from "../../../utils/env";
import { FileMetadata } from "../../interfaces";
import { nanoid } from "nanoid";
import { useDropzone } from "react-dropzone";
import produce from "immer";
import {
  IconDelete,
  IconPause,
  IconPlay,
  IconUpload,
  IconX,
} from "@feedloop/icon";
import useSWR from "swr";
import Papa from "papaparse";
import { useClient } from "../../../utils/QonduitClient";
import { useWorkflowBuilder } from "../../WorkflowBuilderProvider";

type UploaderProgress = {
  id: string;
  status: "uploading" | "paused" | "completed";
  file: File;
  sent: number;
};

const statusBadge = {
  uploading: "grey",
  processing: "grey",
  paused: "yellow",
  canceled: "orange",
  error: "red",
  completed: "green",
  success: "green",
  uploaded: "green",
};

function FileUploaded(props: {
  instanceID: string;
  file: FileUploadMetadata;
  onRemove: (uploadID: string) => void;
}) {
  const { file } = props;

  return (
    <VStack
      alignItems={"normal"}
      padding={"16px"}
      boxShadow={"inset 0px -1px 0px #E9E9E9"}
    >
      <HStack justifyContent={"space-between"}>
        <Text fontWeight={"600"}>{file.location}</Text>
        <Badge colorScheme={statusBadge[file.status]}>{file.status}</Badge>
      </HStack>
      <HStack width={"100%"} justifyContent={"space-between"}>
        <Text>{props.file.cursor} processed</Text>
      </HStack>
    </VStack>
  );
}

function FileUploader(props: {
  progress: UploaderProgress;
  onCancel: (id: string) => void;
  onUploaded: (id: string, sent: number) => void;
  instanceID: string;
}) {
  const {
    state: { id: workflowID },
  } = useWorkflowBuilder();
  const [state, setState] = React.useState<{
    sent: number;
    status: "uploading" | "canceled" | "completed" | "error";
  }>({ sent: 0, status: "uploading" });

  const [hover, setHover] = React.useState(false);

  const handleCancel = React.useCallback(() => {
    props.onCancel(props.progress.id);
  }, [props.onCancel, props.progress.id]);

  const client = useClient();

  React.useEffect(() => {
    let abort = () => {};
    let sent = 0;
    const updateSent = () => {
      setState((state) =>
        produce(state, (draft) => {
          draft.sent = sent;
        })
      );
    };
    // update the status periodically
    const interval = setInterval(() => {
      updateSent();
    }, 1000);
    const parser = Papa.parse(props.progress.file, {
      header: true,
      skipEmptyLines: true,
      step: async function (results, parser) {
        parser.pause();
        await client.sendData(
          props.instanceID,
          workflowID,
          results.data as Object
        );
        sent++;
        console.log(sent);
        parser.resume();
        abort = parser.abort;
      },
      complete: function () {
        updateSent();
        clearInterval(interval);
        props.onUploaded(props.progress.id, sent);
      },
    });

    return () => {
      abort();
      clearInterval(interval);
    };
  }, [props.progress.file, props.onUploaded, props.progress.id]);

  return (
    <VStack
      alignItems={"normal"}
      padding={"16px"}
      boxShadow={"inset 0px -1px 0px #E9E9E9"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <HStack justifyContent={"space-between"}>
        <Text fontWeight={"600"}>{props.progress.file.name}</Text>
        <Badge colorScheme={statusBadge[state.status]}>{state.status}</Badge>
      </HStack>
      <HStack width={"100%"}>
        <Text color={"#454545"} fontSize={"15px"}>
          {state.sent} rows sent
        </Text>
        <Progress
          value={state.sent}
          borderRadius={"4px"}
          height={"6px"}
          width={"380px"}
        />
        {hover && (
          <IconDelete
            fontSize={"20px"}
            style={{ marginLeft: "15px", color: "#6F809A" }}
            cursor={"pointer"}
            onClick={handleCancel}
          />
        )}
      </HStack>
    </VStack>
  );
}

registerNode(
  definition,
  (props) => {
    const [state, setState] = React.useState<{ uploads: UploaderProgress[] }>({
      uploads: [],
    });
    // @ts-ignore
    const files = props.formRef.watch("options.files");
    const client = useClient();

    const handleUploadedFile = React.useCallback(
      (uploadID: string, sent: number) => {
        const file = state.uploads.find((f) => f.id === uploadID);
        if (!file) return;
        const { options } = props.formRef.getValues();
        // @ts-ignore
        props.formRef.setValue("options.files", {
          ...options.files,
          [uploadID]: {
            id: uploadID,
            cursor: sent,
            fields: [],
            size: file.file.size,
            mimetype: file.file.type,
            status: "success",
            location: file.file.name,
            timestamp: new Date(),
          },
        });
        setState((state) =>
          produce(state, (draft) => {
            draft.uploads = draft.uploads.filter(
              (upload) => upload.id !== uploadID
            );
          })
        );
      },
      [state.uploads]
    );

    const handleCancelUpload = React.useCallback((uploadID: string) => {
      setState((state) =>
        produce(state, (draft) => {
          draft.uploads = draft.uploads.filter(
            (upload) => upload.id === uploadID
          );
        })
      );
    }, []);

    const onDrop = React.useCallback((acceptedFiles: File[]) => {
      setState((state) =>
        produce(state, (draft) => {
          for (const file of acceptedFiles) {
            draft.uploads.push({
              id: nanoid(),
              status: "uploading",
              file,
              sent: 0,
            });
          }
        })
      );
    }, []);
    const { getRootProps, getInputProps, isDragActive, draggedFiles } =
      useDropzone({
        onDrop,
        accept: ".csv",
      });

    const handleRemoveFile = React.useCallback((id: string) => {
      const { options } = props.formRef.getValues();
      delete options.files[id];
      // @ts-ignore
      props.formRef.setValue("options.files", options.files);
    }, []);

    return (
      <VStack alignItems={"normal"} spacing={"10px"}>
        <Box
          backgroundColor={"#F9F9FA"}
          padding={"16px"}
          border={"1px solid #E6E9EC"}
          borderRadius={"4px"}
          {...getRootProps()}
        >
          <Box
            borderStyle={"dashed"}
            borderWidth={"2px"}
            _hover={{ borderColor: "gray.500" }}
            borderColor={"gray.300"}
            borderRadius={"6px"}
            cursor={"pointer"}
            width={"100%"}
            height={"100px"}
            display={"flex"}
            alignItems={"center"}
            textAlign={"center"}
            paddingX={"24px"}
            _disabled={{ opacity: 1 }}
          >
            <HStack spacing={"20px"}>
              <Center
                borderRadius={"100%"}
                backgroundColor={"#E6F0FF"}
                width={"50px"}
                height={"50px"}
              >
                <IconUpload
                  style={{
                    zIndex: 100,
                    fontSize: "30px",
                    color: "#0065FF",
                  }}
                />
              </Center>
              {isDragActive ? (
                <Text>Upload {draggedFiles.length} CSV files</Text>
              ) : (
                <Text letterSpacing={"0.5px"}>
                  Drop your CSV files here or{" "}
                  <span style={{ color: "#0065FF" }}>Browse</span>{" "}
                  <input {...getInputProps()} />
                </Text>
              )}
            </HStack>
          </Box>
        </Box>
        {(Object.values(files).length || state.uploads.length) && (
          <Box
            backgroundColor={"#F9F9FA"}
            border={"1px solid #E6E9EC"}
            borderRadius={"4px"}
          >
            {Object.values(files).map((file) => {
              return (
                <FileUploaded
                  instanceID={props.instance.id}
                  file={file}
                  key={file.id}
                  onRemove={handleRemoveFile}
                />
              );
            })}
            {state.uploads.map((progress) => (
              <FileUploader
                progress={progress}
                key={progress.id}
                onCancel={handleCancelUpload}
                onUploaded={handleUploadedFile}
                instanceID={props.instance.id}
              />
            ))}
          </Box>
        )}
      </VStack>
    );
  },
  (props) => {
    return <VStack alignItems="normal"></VStack>;
  }
);
