import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  VStack,
  Button,
  useDisclosure,
  useToast,
  Icon,
  MenuItem,
} from "@chakra-ui/react";
import React from "react";
import { BiDotsHorizontalRounded, BiEditAlt, BiTrash } from "react-icons/bi";
import { useClient } from "../../utils/QonduitClient";
import { QonduitWorkflow } from "../../server/workflowBuilder";
import WorkflowDecisionModal from "../modal/WorkflowDecisionModal";
import useSWR from "swr";
import { useRouter } from "next/dist/client/router";
import RenameWorkflowModal from "../modal/RenameWorkflowModal";
import { menuItemStyle, menuListStyle } from "../../utils/theme";

function SettingsWorkflowMenu({
  workflow,
  onChange,
}: {
  workflow: QonduitWorkflow;
  onChange?: () => void;
}) {
  const client = useClient();
  const deleteWorkflowDisclosure = useDisclosure();
  const editWorkflowDisclosure = useDisclosure();
  // const duplicateWorkflowDisclosure = useDisclosure();
  const router = useRouter();
  const toast = useToast();
  const path = router.asPath.split("/")[2];
  const workflowSettings = [
    { name: "Rename", icon: BiEditAlt, disclosure: editWorkflowDisclosure },
    // {
    //   name: "Duplicate",
    //   icon: BiCopy,
    //   disclosure: duplicateWorkflowDisclosure,
    // },
    { name: "Delete", icon: BiTrash, disclosure: deleteWorkflowDisclosure },
  ];
  const workflows = useSWR([client], async () => {
    const resp = await client.getWorkflows();
    const allWorkflows = resp.data as QonduitWorkflow[];
    const allWorkflowsWithStatus = allWorkflows.map(async (workflow) => {
      const { data } = await client.getWorkflowStatus(workflow.id);
      return { ...workflow, isActive: !!data.isActive };
    });
    return Promise.all(allWorkflowsWithStatus);
  });
  const handleDeleteWorkflow = React.useCallback(async () => {
    await client.deleteWorkflow(workflow?.id);
    path !== "[workflowID]" && path === workflow?.id && router.push("/");
    toast({
      description: "Successfully delete pipeline",
      isClosable: true,
      status: "success",
    });
    await workflows.revalidate();
    deleteWorkflowDisclosure.onClose();
  }, [client, workflows, path, workflow?.id]);
  const handleEditWorkflow = React.useCallback(
    async ({ title }: { title: string }) => {
      try {
        const resp = await client.updateWorkflow(workflow.id, {
          ...workflow,
          title,
        });
        if (resp.status === 200) {
          toast({
            description: "Successfully rename pipeline",
            isClosable: true,
            status: "success",
          });
          editWorkflowDisclosure.onClose();
          if (onChange) onChange();
          else workflows.revalidate();
        } else throw new Error("Failed rename pipeline");
      } catch (error) {
        toast({
          description: "Failed rename pipeline",
          isClosable: true,
          status: "error",
        });
      }
    },
    [client, workflows, workflow?.id]
  );

  return (
    <>
      <Menu autoSelect={false} placement={"bottom"} isLazy>
        <MenuButton
          as={IconButton}
          aria-label="settings"
          icon={<BiDotsHorizontalRounded />}
          variant="unstyled"
          display="flex"
          justifyContent="center"
          alignContent="center"
          size="lg"
          height="min-content"
          minWidth="min-content"
          _focus={{ borderWidth: "0px" }}
          onClick={(e) => e.stopPropagation()}
        />
        <MenuList style={menuListStyle} onClick={(e) => e.stopPropagation()}>
          {workflowSettings.map((option, i) => (
            <MenuItem
              key={i}
              justifyContent="start"
              variant="ghost"
              size="sm"
              onClick={option.disclosure.onOpen}
              icon={
                <Icon
                  as={option.icon}
                  fontSize={"17px"}
                  color={"#A2A2A2 !important"}
                />
              }
              fontWeight="400"
              _hover={menuItemStyle}
            >
              {option.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <RenameWorkflowModal
        workflow={workflow}
        modalDisclosure={editWorkflowDisclosure}
        onSubmit={handleEditWorkflow}
      />
      {/* <DuplicateWorkflowModal
        workflow={workflow}
        modalDisclosure={duplicateWorkflowDisclosure}
      /> */}
      <WorkflowDecisionModal
        modalDisclosure={deleteWorkflowDisclosure}
        modalType="delete"
        submit={handleDeleteWorkflow}
        workflowTitle={workflow?.title}
        cancelAction={deleteWorkflowDisclosure.onClose}
      />
    </>
  );
}

export default SettingsWorkflowMenu;
