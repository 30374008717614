import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Sink,
  "integration",
  {
    to: string;
    cc: string;
    bcc: string;
    subject: string;
    body: string;
    port: string;
    server: string;
    email: string;
    password: string;
    alias: string;
  },
  "Success" | "Failed"
>({
  id: "SMTP Mail",
  type: QonduitResourceTypes.Sink,
  category: "integration",
  color: "#4D93FF",
  copywrite: "Send smtp mail",
  icon: "BiMailSend",
  options: {
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    body: `<div></div>`,
    port: "",
    server: "",
    email: "",
    password: "",
    alias: "",
  },
  schema: {
    type: "object",
    required: [],
    properties: {
      to: { type: "string" },
      cc: { type: "string" },
      bcc: { type: "string" },
      subject: { type: "string" },
      body: { type: "string" },
      port: { type: "string" },
      server: { type: "string" },
      email: { type: "string" },
      password: { type: "string" },
      alias: { type: "string" },
    },
  },
  outputs: ["Success", "Failed"],
});
