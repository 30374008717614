import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Sink,
  "api",
  {
    url: string;
    headers: string;
    body: string;
    method: "GET" | "POST" | "PATCH" | "DELETE";
    contentType: "application/json" | "application/x-www-form-urlencoded";
  },
  "Success" | "Failed"
>({
  id: "HTTP Call",
  type: QonduitResourceTypes.Sink,
  category: "api",
  color: "#4D93FF",
  copywrite: "Request to a server",
  icon: "BiCodeAlt",
  options: {
    url: "",
    contentType: "application/json",
    headers: "{}",
    method: "GET",
    body: `
function buildBody(input) {
  return input
}
    `,
  },
  schema: {
    type: "object",
    required: ["url", "body", "headers"],
    properties: {
      url: { type: "string" },
      headers: { type: "string" },
      body: { type: "string" },
      method: { type: "string", enum: ["GET", "PATCH", "POST", "DELETE"] },
      contentType: {
        type: "string",
        enum: ["application/json", "application/x-www-form-urlencoded"],
      },
    },
  },
  outputs: ["Success", "Failed"],
});
