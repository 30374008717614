import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "data table",
  {
    table: string;
    url: string;
    adminSecret: string;
    fields: Array<{ key: string; value: string }>;
  },
  "Success" | "Failed"
>({
  id: "Qore Data Writer",
  type: QonduitResourceTypes.Operator,
  category: "data table",
  color: "#999922",
  copywrite: "Insert/update data to a table in qore data",
  icon: "BiSave",
  options: {
    table: "users",
    url: "http://localhost:8080",
    adminSecret: "admin-secret",
    fields: [{ key: "name", value: "John" }],
  },
  schema: {
    type: "object",
    required: ["table"],
    properties: {
      table: { type: "string" },
      url: { type: "string" },
      adminSecret: { type: "string" },
      fields: {
        type: "array",
        items: { type: "object", required: ["key", "value"] },
      },
    },
  },
  outputs: ["Success", "Failed"],
});
