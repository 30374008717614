import {
  FormControl,
  FormLabel,
  Select,
  VStack,
  Input,
  Button,
  ButtonGroup,
  useToast,
} from "@chakra-ui/react";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";
import Editor from "@monaco-editor/react";
import React from "react";
import QonduitContext from "../../../components/QonduitContext";

registerNode(
  definition,
  (props) => {
    const handleHeadersChange = React.useCallback(
      (val?: string) => {
        if (!val) return;
        props.formRef.setValue("options.headers", val);
      },
      [props.modifyOptions]
    );
    const handleBodyChange = React.useCallback(
      (val?: string) => {
        if (!val) return;
        props.formRef.setValue("options.body", val);
      },
      [props.modifyOptions]
    );
    return (
      <VStack spacing="5">
        <FormControl>
          <FormLabel>URL</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.url")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Method</FormLabel>
          <Select
            {...props.formRef.register("options.method")}
            backgroundColor="white"
          >
            {definition.schema.properties.method.enum.map((method: string) => {
              return (
                <option key={method} value={method}>
                  {method}
                </option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Content Type</FormLabel>
          <Select
            {...props.formRef.register("options.contentType")}
            backgroundColor="white"
          >
            {definition.schema.properties.contentType.enum.map(
              (contentType: string) => {
                return (
                  <option key={contentType} value={contentType}>
                    {contentType}
                  </option>
                );
              }
            )}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Headers</FormLabel>
          <Editor
            height="100px"
            theme="vs-dark"
            defaultLanguage="json"
            defaultValue={props.instance.options.headers}
            onChange={handleHeadersChange}
            options={{ minimap: { enabled: false } }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Body</FormLabel>
          <Editor
            height="200px"
            theme="vs-dark"
            defaultLanguage="javascript"
            defaultValue={props.instance.options.body}
            onChange={handleBodyChange}
            options={{ minimap: { enabled: false } }}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return <div></div>;
  }
);
