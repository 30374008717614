import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export default buildProcessorDefinition<
  QonduitResourceTypes.Operator,
  "operation",
  { path: string },
  "Events"
>({
  id: "SplitJSON",
  type: QonduitResourceTypes.Operator,
  category: "operation",
  color: "#4390d8",
  copywrite: "Split provided JSON data",
  icon: "BiShuffle",
  options: { path: "path.to.array" },
  schema: {
    type: "object",
    required: ["path"],
    properties: { path: { type: "string" } },
  },
  outputs: ["Events"],
});
