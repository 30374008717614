import { useDisclosure } from "@chakra-ui/hooks";
import { UseDisclosureReturn, useToast } from "@chakra-ui/react";
import { JSONSchema7Object } from "json-schema";
import React from "react";
import { useForm, UseFormReturn, NestedValue } from "react-hook-form";
import { useClient } from "../utils/QonduitClient";
import {
  QonduitProcessorDefinition,
  QonduitResourceTypes,
  QonduitResourceCategories,
} from "./interfaces";
import { ProcessorInstance } from "./ProcessorInstance";
import NodeWrapper from "./NodeWrapper";
import { useWorkflowBuilder } from "./WorkflowBuilderProvider";

const nodeTypes: Record<
  string,
  React.ComponentType<{
    data: {
      instance: ProcessorInstance<any>;
    };
  }>
> = {};

export function getNodeTypes() {
  return nodeTypes;
}

export function registerNode<
  TResourceType extends QonduitResourceTypes = QonduitResourceTypes,
  TResourceCategory extends QonduitResourceCategories = QonduitResourceCategories,
  TSchemaObject extends JSONSchema7Object = JSONSchema7Object,
  TOutputs extends string = string
>(
  definition: QonduitProcessorDefinition<
    TResourceType,
    TResourceCategory,
    TSchemaObject,
    TOutputs
  >,
  Setting: React.ComponentType<{
    instance: ProcessorInstance<TSchemaObject>;
    portalRef: React.MutableRefObject<any>;
    modifyMetadata: (
      modifier: (draft: ProcessorInstance["metadata"]) => void
    ) => void;
    modifyOptions: (
      modifier: (draft: ProcessorInstance<TSchemaObject>["options"]) => void
    ) => void;
    optionsValue: TSchemaObject;
    formRef: UseFormReturn<{
      options: NestedValue<TSchemaObject>;
      metadata: NestedValue<ProcessorInstance["metadata"]>;
    }>;
    setOptions: React.Dispatch<React.SetStateAction<TSchemaObject>>;
    modalContentDisclosure?: UseDisclosureReturn;
    nextmodalContentDisclosure?: UseDisclosureReturn;
  }>,
  Display: React.ComponentType<{
    instance: ProcessorInstance<TSchemaObject>;
  }>
) {
  nodeTypes[definition.id] = ({
    data,
  }: {
    data: {
      instance: ProcessorInstance<TSchemaObject>;
    };
  }) => {
    const { build, state } = useWorkflowBuilder();
    const formRef = useForm<{
      options: NestedValue<TSchemaObject>;
      metadata: NestedValue<ProcessorInstance["metadata"]>;
    }>({
      defaultValues: {
        // @ts-ignore
        options: data.instance.options,
        metadata: data.instance.metadata,
      },
      mode: "onChange",
    });
    const [optionsState, setOptionsState] = React.useState<TSchemaObject>(
      data.instance.options
    );
    const portalRef = React.useRef<HTMLDivElement>(null);
    const nextmodalContentDisclosure = useDisclosure();
    const modalContentDisclosure = useDisclosure();
    const modalBucketContentDisclosure = useDisclosure();
    const modifyMetadata = React.useCallback(
      (modifier: (draft: ProcessorInstance["metadata"]) => void) => {
        build((builder) => {
          builder.manager.modifyInstance(data.instance.id, (draft) => {
            modifier(draft.metadata);
          });
        });
      },
      [data.instance.id, build]
    );

    const modifyOptions = React.useCallback(
      (modifier: (draft: typeof data.instance["options"]) => void) => {
        build((builder) => {
          builder.manager.modifyInstance(data.instance.id, (draft) => {
            // @ts-ignore
            modifier(draft.options);
          });
        });
      },
      [data.instance.id, build]
    );

    const client = useClient();

    React.useEffect(() => {
      const timeout = setTimeout(async () => {
        const updated = state.instances[data.instance.id];
        if (!updated) return;
        build((builder) => {
          builder.manager.modifyInstance(data.instance.id, (draft) => {
            draft.metadata = updated.metadata;
            draft.options = updated.options;
            draft.subscriptions = updated.subscriptions;
          });
        });
        await client.updateWorkflowProcessor(updated.id, state.id, updated);
      }, 300);
      return () => clearTimeout(timeout);
    }, [state.instances[data.instance.id], build]);
    return (
      <NodeWrapper
        instance={data.instance}
        modifyMetadata={modifyMetadata}
        modifyOptions={modifyOptions}
        formRef={formRef}
        portalRef={portalRef}
        optionsValue={optionsState}
        setOptions={setOptionsState}
        nextmodalContentDisclosure={nextmodalContentDisclosure}
        modalContentDisclosure={modalContentDisclosure}
        modalBucketContentDisclosure={modalBucketContentDisclosure}
        settingModal={
          <Setting
            formRef={formRef}
            portalRef={portalRef}
            instance={data.instance}
            modifyMetadata={modifyMetadata}
            modifyOptions={modifyOptions}
            optionsValue={optionsState}
            setOptions={setOptionsState}
            nextmodalContentDisclosure={nextmodalContentDisclosure}
            modalContentDisclosure={modalContentDisclosure}
          />
        }
        expandedContent={<Display instance={data.instance} />}
      />
    );
  };
}
