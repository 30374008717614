import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  VStack,
} from "@chakra-ui/react";
import Editor from "@monaco-editor/react";
import React from "react";
import { registerNode } from "../../ReactNodes";
import definition from "./definition";

registerNode(
  definition,
  (props) => {
    const handleBodyChange = React.useCallback(
      (val?: string) => {
        if (!val) return;
        props.formRef.setValue("options.body", val);
      },
      [props.modifyOptions]
    );
    const [showCC, setShowCC] = React.useState<boolean>(false);
    const [showBCC, setShowBCC] = React.useState<boolean>(false);
    return (
      <VStack spacing="5">
        <FormControl>
          <FormLabel>To</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.to")}
          />
        </FormControl>
        <FormControl
          style={{
            display: showCC || props.instance.options.cc ? "initial" : "none",
          }}
        >
          <FormLabel>Cc</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.cc")}
          />
        </FormControl>
        <FormControl
          style={{
            display: showBCC || props.instance.options.bcc ? "initial" : "none",
          }}
        >
          <FormLabel>Bcc</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.bcc")}
          />
        </FormControl>
        <HStack width={"full"}>
          <Link
            fontWeight={400}
            fontSize={"12px"}
            color="#0065FF"
            onClick={() => setShowCC(true)}
            style={{
              display:
                !showCC && !props.instance.options.cc ? "initial" : "none",
            }}
          >
            + Add CC
          </Link>
          <Link
            fontWeight={400}
            fontSize={"12px"}
            color="#0065FF"
            onClick={() => setShowBCC(true)}
            style={{
              display:
                !showBCC && !props.instance.options.bcc ? "initial" : "none",
            }}
          >
            + Add BCC
          </Link>
        </HStack>
        <FormControl>
          <FormLabel>Subject</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.subject")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Body</FormLabel>
          <Editor
            height="200px"
            theme="vs-dark"
            defaultLanguage="html"
            defaultValue={props.instance.options.body}
            onChange={handleBodyChange}
            options={{ minimap: { enabled: false } }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Port</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.port")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Server</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.server")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.email")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.password")}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email Alias</FormLabel>
          <Input
            backgroundColor="white"
            {...props.formRef.register("options.alias")}
          />
        </FormControl>
      </VStack>
    );
  },
  (props) => {
    return <div></div>;
  }
);
