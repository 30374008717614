import { buildProcessorDefinition } from "../../definitions";
import { QonduitResourceTypes } from "../../interfaces";

export type FrequencyVariant =
  | {
      name: "Hourly";
      isRange: boolean;
      startTime: string;
      endTime: string;
      desc: "When enabled, first run of this trigger will be at start time, and last run will be at end time";
      template: string;
      count: 0;
      date: 0;
      type: "";
      value: 0;
      day: 0;
    }
  | {
      name: "Daily";
      desc: "This trigger will run once daily at designated time.";
      template: string;
      count: 1;
      startTime: "";
      endTime: "";
      isRange: boolean;
      date: 0;
      type: "";
      value: 0;
      day: 0;
    }
  | {
      name: "Workdays (Mon-Fri)";
      desc: "This trigger will run once each workdays (Monday to Friday) at designated time.";
      template: string;
      count: 5;
      isRange: boolean;
      startTime: "";
      endTime: "";
      date: 0;
      type: "";
      value: 0;
      day: 0;
    }
  | {
      name: "Weekly";
      day: number;
      desc: "This trigger will run once each week at designated day.";
      template: string;
      count: 7;
      isRange: boolean;
      startTime: "";
      endTime: "";
      date: 0;
      type: "";
      value: 0;
    }
  | {
      name: "Monthly";
      date: number;
      desc: "This trigger will run each month at designated date and time.";
      template: string;
      count: 28;
      isRange: boolean;
      startTime: "";
      endTime: "";
      type: "";
      value: 0;
      day: 0;
    }
  | {
      name: "Custom interval...";
      value: number;
      template: string;
      isRange: boolean;
      type: string;
      startTime: string;
      endTime: string;
      desc: "When enabled, first run of this trigger will be at start time, and last run will be at end time.";
      count: 0;
      date: 0;
      day: 0;
    };

export default buildProcessorDefinition<
  QonduitResourceTypes.Source,
  "trigger",
  {
    frequency: FrequencyVariant;
    isRepeat: boolean;
    startDate: string;
    endDate: string;
    script: string;
    tz: string;
  },
  "Inbound"
>({
  id: "Scheduler",
  type: QonduitResourceTypes.Source,
  category: "trigger",
  color: "#329922",
  copywrite: "Schedule a process to run",
  icon: "BiAlarm",
  options: {
    frequency: {
      name: "Hourly",
      isRange: false,
      template: "m */1 * * *",
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      desc: "When enabled, first run of this trigger will be at start time, and last run will be at end time",
      count: 0,
      date: 0,
      type: "",
      value: 0,
      day: 0,
    },
    isRepeat: true,
    startDate: new Date(new Date().setSeconds(0)).toISOString(),
    endDate: new Date(new Date().setHours(23, 0, 0)).toISOString(),
    script: "0 */1 * * *",
    tz: "Asia/Jakarta",
  },
  schema: {
    type: "object",
    required: ["frequency", "isRepeat", "startDate", "endDate", "script"],
    properties: {
      isRepeat: { type: "boolean" },
      script: { type: "string" },
      startDate: { type: "string" },
      endDate: { type: "string" },
      tz: {
        type: "string",
      },
      frequency: {
        type: "object",
        enum: [
          {
            name: "Hourly",
            isRange: false,
            startTime: new Date().toISOString(),
            endTime: new Date().toISOString(),
            desc: "When enabled, first run of this trigger will be at start time, and last run will be at end time",
            template: "m */1 * * *",
            count: 0,
            date: 0,
            type: "",
            value: 0,
            day: 0,      
          },
          {
            name: "Daily",
            desc: "This trigger will run once daily at designated time.",
            template: "m h */1 * *",
            count: 1,
            startTime: "",
            endTime: "",
            isRange: false,
            date: 0,
            type: "",
            value: 0,
            day: 0,    
          },
          {
            name: "Workdays (Mon-Fri)",
            desc: "This trigger will run once each workdays (Monday to Friday) at designated time.",
            template: "m h * * 1-5",
            count: 5,
            isRange: false,
            startTime: "",
            endTime: "",
            date: 0,
            type: "",
            value: 0,
            day: 0,    
          },
          {
            name: "Weekly",
            day: 1,
            desc: "This trigger will run once each week at designated day.",
            template: "m h * * D",
            count: 7,
            isRange: false,
            startTime: "",
            endTime: "",
            date: 0,
            type: "",
            value: 0,
            },
          {
            name: "Monthly",
            date: 15,
            desc: "This trigger will run each month at designated date and time.",
            template: "m h d * *",
            count: 28,
            isRange: false,
            startTime: "",
            endTime: "",
            type: "",
            value: 0,
            day: 0,    
          },
          {
            name: "Custom interval...",
            value: 3,
            template: "m h/c * * *",
            type: "hours",
            isRange: false,
            startTime: new Date().toISOString(),
            endTime: new Date().toISOString(),
            desc: "When enabled, first run of this trigger will be at start time, and last run will be at end time.",
            count: 0,
            date: 0,
            day: 0,     
          },
        ],
        required: [],
      },
    },
  },
  outputs: ["Inbound"],
});

export function getTimeWithoutDate(datetime: Date){
  return `${String(datetime.getUTCHours()).padStart(2, '0')}:${String(datetime.getUTCMinutes()).padStart(2, '0')}:${String(datetime.getUTCSeconds()).padStart(2, '0')}`;
}

export function dateDiff (dateFrom:Date, dateTo:Date, diffType:"days"|"months"|"weeks"): number{
  const msPerDay = 1000 * 60 * 60 * 24;
  const utcFrom = Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate());
  const utcTo = Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate());

  switch (diffType){
    case "days":    
      return Math.floor((utcTo - utcFrom) / msPerDay); 
    case "months":
      return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
    case "weeks":
      const msPerWeek = msPerDay * 7;
      return Math.round(Math.abs(utcFrom - utcTo) / msPerWeek);
  }
}
